import Account from "./Account";

class Provider {
    constructor(rawJson) {
        // Fields directly from json
        this.active = rawJson.active;
        this.balance = rawJson.balance;
        this.billingPhoneNumber = rawJson.billingPhoneNumber;
        this.brandedWalletUrl = rawJson.brandedWalletUrl;
        this.encounterGroupId = rawJson.encounterGroupId;
        this.features = rawJson.features;
        this.id = rawJson.id;
        this.logos = rawJson.logos;
        this.name = rawJson.name;
        this.patientDueDateOverrideText = rawJson.patientDueDateOverrideText;
        this.paymentPlanInstallmentOptions = rawJson.paymentPlanInstallmentOptions;
        this.paymentPrePlanInstallmentOptions = rawJson.paymentPrePlanInstallmentOptions;
        this.requiresRedirectToBranded = rawJson.requiresRedirectToBranded;
        this.websites = rawJson.websites;
        this.additionalPhones = rawJson.additionalPhones;
        this.usaepayPublicKey = rawJson.usaepayPublicKey;
        this.limits = {};
        this.limits.paymentMaxScheduleDays = rawJson.paymentMaxScheduleDays;
        this.accountNumberLabel = rawJson.accountNumberLabel;

        // Nested objects
        if (rawJson.accounts) {
            this.accounts = rawJson.accounts.map(account => new Account(account, this));
        }

        // Calculated fields
        this.canMessage = this.active && this.hasFeature('messaging');
        this.friendlyDueAmount = { amount: this.balance };
        this.npsInApp = this.active && this.hasFeature('npsInApp');
        this.subAccountPaymentsEnabled = this.hasFeature('newWalletPaymentFlow') && this.hasFeature('subAccounts');
        this.estimatePaymentsEnabled = this.hasFeature('subAccounts') && this.hasFeature('estimates');
    }

    hasFeature(featureName) {
        return this.features.includes(featureName);
    }
}

export default Provider;
