<template>
    <Modal :canClose="true" @close="close" :show="promptVisible" class="first-bill-verification-modal">

            <ProviderLogo :provider-id="providerId" :alt-text="providerName"></ProviderLogo>
            <!--  verification options  -->

            <div class='verification-options-section' v-if="currentStep=='options'">
                <div v-if="authPossible">
                    <h2 class="modal-header h-style-2">{{ this.$translate('firstComm.optionsHeader') }}</h2>
                    <h3 class="modal-subheader h-style-4">{{ optionsSubheader }}</h3>

                    <div class="button-container" >
                        <button class="fs-block u-truncate first-bill-verify-email" v-if="authOptions.canEmailAuth"
                            @click="sendCode(false); moveToState('verify-email');">
                            {{$translate('firstComm.verifyViaEmailOption', {emailAddress: authOptions.emailAddress})}}
                        </button>

                        <button class="fs-block u-truncate first-bill-verify-sms" v-if="authOptions.canPhoneAuth"
                             @click="sendCode(false); moveToState('verify-sms');">
                            {{$translate('firstComm.verifyViaSmsOption', {phoneNumber: authOptions.phoneNumber})}}
                        </button>

                        <button class="fs-block u-truncate first-bill-verify-pdiv" v-if="authOptions.canPdivAuth"
                             @click="moveToState('verify-pdiv');">
                            {{$translate('firstComm.verifyViaPdiv')}}
                        </button>
                    </div>
                </div>

                <div class="auth-not-possible" v-if="!authPossible">{{unableToAuth}}</div>
            </div>

            <!--  verify-email  -->

            <div class='verification-email-section' v-if="currentStep=='verify-email'">
                <h2 class="modal-header h-style-2">{{$translate('firstComm.verifyEmailHeader')}}</h2>

                <!-- envelope icon -->
                <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style="max-width: 100px;margin-bottom: -30px;fill:#616161;"><g transform="translate(0,-952.36218)"><path style="text-indent:0;text-transform:none;direction:ltr;block-progression:tb;baseline-shift:baseline;color:#000000;enable-background:accumulate;" d="m 6.0000002,973.36217 a 2.0002,2.0002 0 0 0 -2,2 l 0,54.00003 a 2.0002,2.0002 0 0 0 2,2 l 87.9999998,0 a 2.0002,2.0002 0 0 0 2,-2 l 0,-54.00003 a 2.0002,2.0002 0 0 0 -2,-2 l -87.9999998,0 z m 5.4687498,4 77.0625,0 L 50,1009.7684 11.46875,977.36217 z m -3.4687498,2.28125 25.9687498,21.84378 -25.9687498,23.375 0,-45.21878 z m 83.9999998,0 0,45.21878 -25.96875,-23.375 L 92,979.64342 z m -54.9375,24.43748 11.65625,9.8125 a 2.0002,2.0002 0 0 0 2.5625,0 l 11.65625,-9.8125 25.875,23.2813 -77.625,0 25.875,-23.2813 z" fill-opacity="1" stroke="none" marker="none" visibility="visible" display="inline" overflow="visible"/></g></svg>
                <h3 class="modal-subheader h-style-4">{{verifyEmailSubheader}}</h3>

                <Notification class="notification-inline failure" v-if="codeNotSent">
                    <base-icon icon="mdi-close-circle"></base-icon>
                    {{ this.$translate('firstComm.codeNotSent') }}
                </Notification>
                <Notification class="notification-inline success" v-if="codeSent">
                    <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                    {{$translate('firstComm.codeSent')}}
                </Notification>

                <form name="forms.verifyEmailCodeForm" @submit.prevent @submit="submitEmailCode()" novalidate>
                    <div v-if="verifyNotifications.bruteForce || verifyNotifications.genericError" class="interaction-feedback">
                        <div v-if="verifyNotifications.bruteForce" class="vue-messages">
                            <div class="vue-message brute-force" v-html="$translate('firstComm.bruteForce')"></div>
                        </div>
                        <div v-if="verifyNotifications.genericError" class="vue-messages">
                            <div class="vue-message generic-error" v-html="$translate('firstComm.codeInvalid')"></div>
                        </div>
                    </div>

                    <div class="input-area-wrapper" :class="{errored: forms.verifyEmailCodeForm.$invalid && forms.verifyEmailCodeForm.$submitted}">

                        <div class="input-area-field-wrapper">
                            <input name="emailVerificationCode" type="text" inputmode="decimal" all-numeric required
                                    :placeholder="$translate('labels.verificationCode')"
                                    v-model="fields.emailVerificationCode" tabindex="0"
                                    @blur="validateEmailCode()"
                                    maxlength="6" />
                            <a class="inline resend" @click="sendCode(true);">Resend</a>
                        </div>

                        <div class="input-area-feedback">
                            <div class="vue-messages" v-if="forms.verifyEmailCodeForm && forms.verifyEmailCodeForm.emailVerificationCode.$error.required" >
                                <div class="vue-message">
                                    {{$translate('errors.genericRequired')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div v-if="currentStep=='verify-email'" class="modal-actions verification-email-actions">
                <button type="button" class="first-comm-back"  @click="moveToState('options')"
                    v-show="!oneOption">{{$translate('firstComm.back')}}</button>
                <button class="button-primary submit-button" @click="submitEmailCode()">{{submitButtonText}}</button>
            </div>

            <!--  verify-sms  -->

            <div class='verification-sms-section' v-if="currentStep=='verify-sms'">
                <h2 class="modal-header h-style-2" >{{$translate('firstComm.verifySmsHeader')}}</h2>

                <!-- phone icon -->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 96 120" x="0px" y="0px" style="max-width: 100px;margin-bottom: -20px;fill:#616161;"><path d="M68 96h-40c-4.416 0-8-3.584-8-8v-80c0-4.416 3.584-8 8-8h40c4.416 0 8 3.584 8 8v80c0 4.416-3.584 8-8 8zM28 4c-2.208 0-4 1.796-4 4v80c0 2.204 1.792 4 4 4h40c2.208 0 4-1.796 4-4v-80c0-2.204-1.792-4-4-4h-40z" /><path d="M72 16h-48c-1.1 0-2-0.896-2-2s0.9-2 2-2h48c1.1 0 2 0.896 2 2s-0.9 2-2 2z" /><path d="M72 80h-48c-1.1 0-2-0.9-2-2s0.9-2 2-2h48c1.1 0 2 0.9 2 2s-0.9 2-2 2z" /><path d="M52 88h-8c-1.1 0-2-0.9-2-2s0.9-2 2-2h8c1.1 0 2 0.9 2 2s-0.9 2-2 2z" /></svg>
                <h3 class="modal-subheader h-style-4">{{verifySmsSubheader}}</h3>

                <Notification class="notification-inline failure" v-if="codeNotSent">
                    <base-icon icon="mdi-close-circle"></base-icon>
                    {{$translate('firstComm.codeNotSent')}}
                </Notification>  
                <Notification class="notification-inline success" v-if="codeSent">
                    <base-icon icon="mdi-checkbox-marked-circle"></base-icon>
                    {{$translate('firstComm.codeSent')}}
                </Notification>              

                <form name="forms.verifySmsCodeForm" @submit="submitSmsCode()" @submit.prevent novalidate>
                    <div v-if="verifyNotifications.bruteForce || verifyNotifications.genericError" class="interaction-feedback">
                        <div v-if="verifyNotifications.bruteForce" class="vue-messages">
                            <div class="vue-message brute-force" v-html="$translate('firstComm.bruteForce')"></div>
                        </div>
                        <div v-if="verifyNotifications.genericError" class="vue-messages">
                            <div class="vue-message generic-error" v-html="$translate('firstComm.codeInvalid')"></div>
                        </div>
                    </div>

                    <div class="input-area-wrapper" :class="{errored: forms.verifySmsCodeForm.$invalid && forms.verifySmsCodeForm.$submitted}">

                        <div class="input-area-field-wrapper">
                            <input name="smsVerificationCode" type="text" inputmode="decimal" all-numeric required
                                   :placeholder="$translate('labels.verificationCode')"
                                   v-model="fields.smsVerificationCode" tabindex="0"
                                   @blur="validateSMSCode()"
                                   maxlength="6" autocomplete="off" />
                            <a class="inline resend"  @click="sendCode(true);">Resend</a>
                        </div>

                        <div class="input-area-feedback">
                            <div class="vue-messages" v-if="forms.verifySmsCodeForm && forms.verifySmsCodeForm.smsVerificationCode.$error.required" >
                                <div class="vue-message">
                                    {{$translate('errors.genericRequired')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div v-if="currentStep=='verify-sms'" class="modal-actions verification-sms-actions">
                <button type="button" class="first-comm-back"  @click="moveToState('options')"
                    v-show="!oneOption">{{$translate('firstComm.back')}}</button>
                <button class="button-primary submit-button" @click="submitSmsCode()">{{submitButtonText}}</button>
            </div>

            <!--  verify-pdiv  -->

            <div class='verification-pdiv-section' v-if="currentStep=='verify-pdiv'">
                <h3 class="modal-subheader h-style-4">{{verifyPdivHeader}}</h3>

                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style="enable-background:new 0 0 100 100;max-width: 100px;margin-bottom: -40px;fill:#616161" xml:space="preserve"><path d="M53,72c0,0.7,0.1,1.3,0.1,2H31c-1.1,0-2-0.9-2-2v-6c0-8.2,6.2-15,14.3-15.9C39.5,47.8,37,43.7,37,39c0-7.2,5.8-13,13-13  s13,5.8,13,13c0,4.7-2.5,8.8-6.3,11.1c4.4,0.5,8.2,2.7,10.8,6C59.4,56.8,53,63.7,53,72z M77.5,80.5c-4.7,4.7-12.3,4.7-17,0  s-4.7-12.3,0-17c4.7-4.7,12.3-4.7,17,0S82.2,75.8,77.5,80.5z M74.7,68.3c-0.4-0.4-1-0.4-1.4,0L68,73.6l-3.3-3.3  c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4,4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l6-6C75.1,69.3,75.1,68.7,74.7,68.3z"/></svg>
               
                <form name="forms.verifyPdivForm" @submit="submitPdiv()" @submit.prevent novalidate>
                    <div v-if="verifyNotifications.bruteForce || verifyNotifications.genericError" class="interaction-feedback">
                        <div v-if="verifyNotifications.bruteForce" class="vue-messages">
                            <div class="vue-message brute-force" v-html="$translate('firstComm.bruteForce')"></div>
                        </div>
                        <div v-if="verifyNotifications.genericError" class="vue-messages">
                            <div class="vue-message generic-error" v-html="$translate('firstComm.pdivInvalid')"></div>
                        </div>
                    </div>

                    <div v-if="authOptions.pdivFields.guarantorDOB"
                        :class="{errored: forms.verifyPdivForm.birthdate.$invalid && forms.verifyPdivForm.$submitted}"
                        class="input-area-wrapper">

                        <div class="input-area-field-wrapper">
                            <input class="fs-block" v-model="fields.birthdate" type="text" inputmode="decimal" pastdate required maxlength="10" autocomplete="off"
                                name="birthdate" :key="birthdateKey" @blur="validateBirthdate()" @keypress="birthdateKeypress($event)" @input="birthdateInput($event)" :placeholder="$translate('firstComm.birthdatePlaceholder')"/>
                        </div>

                        <div class="input-area-feedback">
                            <div v-if="forms.verifyPdivForm.$submitted" class="vue-messages">
                                <div class="vue-message" v-if="forms.verifyPdivForm.birthdate.$error.incomplete">{{$translate('errors.incompleteBirthdate')}}</div>
                                <div class="vue-message" v-if="forms.verifyPdivForm.birthdate.$error.required">{{$translate('errors.genericRequired')}}</div>
                                <div class="vue-message" v-if="forms.verifyPdivForm.birthdate.$error.invalidDate">{{$translate('errors.invalidDate')}}</div>
                                <div class="vue-message" v-if="forms.verifyPdivForm.birthdate.$error.futureDate">{{$translate('errors.futureBirthdate')}}</div>
                            </div>
                        </div>
                    </div>

                    <div v-if="authOptions.pdivFields.guarantorLast4SSN"
                        :class="{errored: (forms.verifyPdivForm.SSN.$invalid) && forms.verifyPdivForm.$submitted}"
                        class="input-area-wrapper">

                        <div class="input-area-field-wrapper">
                            <input class="fs-block" v-model="fields.SSN" type="text" inputmode="decimal" name="SSN" required minlength="4" maxlength="4" all-numeric
                                :placeholder="$translate('firstComm.SSNPlaceholder')" @blur="validateSSN()" autocomplete="off"/>
                        </div>

                        <div class="input-area-feedback">
                            <div v-if="forms.verifyPdivForm.$submitted" class="vue-messages">
                                <div class="vue-message" v-if="forms.verifyPdivForm.SSN.$error.required">{{$translate('errors.genericRequired')}}</div>
                                <div class="vue-message" v-if="forms.verifyPdivForm.SSN.$error.minlength">{{$translate('firstComm.incompleteSSN')}}</div>
                            </div>
                        </div>
                    </div>

                    <div v-if="authOptions.pdivFields.zip"
                        :class="{errored: (forms.verifyPdivForm.zip.$invalid) && forms.verifyPdivForm.$submitted}"
                        class="input-area-wrapper">

                        <div class="input-area-field-wrapper">
                            <input class="fs-block" v-model="fields.zip" type="text" inputmode="decimal" name="zip" required minlength="5" maxlength="5" all-numeric
                                :placeholder="$translate('labels.zip')" @blur="validateZip()" autocomplete="off"/>
                        </div>

                        <div class="input-area-feedback">
                            <div v-if="forms.verifyPdivForm.$submitted" class="vue-messages">
                                <div class="vue-message" v-if="forms.verifyPdivForm.zip.$error.required">{{$translate('errors.genericRequired')}}</div>
                                <div class="vue-message" v-if="forms.verifyPdivForm.zip.$error.minlength">{{$translate('errors.minZipCode')}}</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div v-if="currentStep=='verify-pdiv'" class="modal-actions verification-pdiv-actions">
                <button type="button" class="first-comm-back"  @click="moveToState('options')"
                    v-show="!oneOption">{{$translate('firstComm.back')}}</button>
                <button type="submit" class="button-primary submit-button"  @click="submitPdiv()">{{submitButtonText}}</button>
            </div>

	</Modal>
</template>

<script>

import Modal from './Modal.vue';
import Notification from './Notification.vue';
import ProviderLogo from './ProviderLogo.vue';
import moment from 'moment';

import BaseIcon from './BaseIcon.vue';

const billType = 'bill',
      estimateType = 'estimate';

export default {
    name: 'FirstCommVerificationModal',
    components: {
        Modal,
        Notification,
        ProviderLogo,
        BaseIcon
    },
    data() {
        return {
            // Internal Data
            token: '',
            type: null,
            codeSendInProgress: false,
            verifyInProgress: false,

            // used to rerender the birthdate input when we manually edit it
            birthdateKey: 0,

            // Stuff used in the template
            providerId: null,
            providerName: '',
            currentStep: 'options',
            authPossible: false,
            authOptions: {},
            oneOption: false,
            submitButtonText: '',
            codeNotSent: false,
            codeSent: false,
            promptVisible: false,
            verifyNotifications: {},
            fields: {},
            forms: {},

            // Some text that gets set based on whether this is a bill or estimate
            optionsSubheader: '',
            unableToAuth: '',
            verifyEmailSubheader: '',
            verifySmsSubheader: '',
            verifyPdivHeader: '',
        };
    },
    computed: {
        currentEstimate() {
            return this.$store.getters.currentEstimate;
        },
        getFullstory() {
            return this.$store.getters.getFullstory;
        }
    },
    methods: {
        isValidCommType(type) {
            return (type === billType || type === estimateType);
        },
        resetNotifications() {
            this.verifyNotifications = {};
        },
        // Validation functions
        validateBirthdate() {
            this.forms.verifyPdivForm.birthdate.$error = {};
            let error = false;
            if (!this.fields.birthdate) {
                this.forms.verifyPdivForm.birthdate.$error.required = true;
                error = true;
            // generic formatting regex to show generic error
            } else if (!this.fields.birthdate.match(/\d\d\/\d\d\/\d\d\d\d/)) {
                this.forms.verifyPdivForm.birthdate.$error.incomplete = true;
                error = true;
            // more specific regex for valid birthdates (restricts year to 1900 minimum)
            } else if (!this.fields.birthdate.match(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/) || !moment(this.fields.birthdate).isValid()) {
                this.forms.verifyPdivForm.birthdate.$error.invalidDate = true;
                error = true;
            } else if (moment(this.fields.birthdate).isAfter(moment())) {
                this.forms.verifyPdivForm.birthdate.$error.futureDate = true;
                error = true;
            }
            if (error) {
                this.forms.verifyPdivForm.birthdate.$invalid = true;
                this.forms.verifyPdivForm.$invalid = true;
            } else {
                this.forms.verifyPdivForm.birthdate.$invalid = false;
            }
        },
        validateSSN() {
            this.forms.verifyPdivForm.SSN.$error = {};
            let error = false;
            if (!this.fields.SSN) {
                this.forms.verifyPdivForm.SSN.$error.required = true;
                error = true;
            } else if (this.fields.SSN.length < 4) {
                this.forms.verifyPdivForm.SSN.$error.minlength = true;
                error = true;
            }
            if (error) {
                this.forms.verifyPdivForm.SSN.$invalid = true;
                this.forms.verifyPdivForm.$invalid = true;
            } else {
                this.forms.verifyPdivForm.SSN.$invalid = false;
            }
        },
        validateZip() {
            this.forms.verifyPdivForm.zip.$error = {};
            let error = false;
            if (!this.fields.zip) {
                this.forms.verifyPdivForm.zip.$error.required = true;
                error = true;
            } else if (this.fields.zip.length < 5) {
                this.forms.verifyPdivForm.zip.$error.minlength = true;
                error = true;
            }
            if (error) {
                this.forms.verifyPdivForm.zip.$invalid = true;
                this.forms.verifyPdivForm.$invalid = true;
            } else {
                this.forms.verifyPdivForm.zip.$invalid = false;
            }
        },
        validateEmailCode() {
            this.forms.verifyEmailCodeForm.emailVerificationCode.$error = {};
            let error = false;
            if (!this.fields.emailVerificationCode || this.fields.emailVerificationCode === '') {
                this.forms.verifyEmailCodeForm.emailVerificationCode.$error.required = true;
                this.forms.verifyEmailCodeForm.$invalid = true;
            } else {
                this.forms.verifyEmailCodeForm.$invalid = false;
            }
        },
        validateSMSCode() {
            this.forms.verifySmsCodeForm.smsVerificationCode.$error = {};
            let error = false;
            if (!this.fields.smsVerificationCode || this.fields.smsVerificationCode === '') {
                this.forms.verifySmsCodeForm.smsVerificationCode.$error.required = true;
                this.forms.verifySmsCodeForm.$invalid = true;
            } else {
                this.forms.verifySmsCodeForm.$invalid = false;
            }
        },
        birthdateKeypress(event) {
            if (isNaN(Number(event.key)) && event.key !== '/') {
                event.preventDefault();
                return;
            }
        },
        birthdateInput(e) {
            if (!this.fields.birthdate) {
                return;
            }
            if (e.inputType ===  'deleteContentBackward') {
                return;
            }
            let unformattedBirthdate = this.fields.birthdate;
            unformattedBirthdate = unformattedBirthdate.replace(/\D/g,'');
            if (unformattedBirthdate.length > 8) {
                unformattedBirthdate = unformattedBirthdate.slice(0, 8);
            }
            if (unformattedBirthdate.length >= 1 && parseInt(unformattedBirthdate.charAt(0)) > 1) {
                unformattedBirthdate = '0' + unformattedBirthdate;
            }
            // if we have more characters after where the first slash would go, or if the original input had a slash at the end, put the slash back
            if (unformattedBirthdate.length > 2 || (this.fields.birthdate.length === 3 && unformattedBirthdate.length === 2)) {
                unformattedBirthdate = unformattedBirthdate.slice(0, 2) + '/' + unformattedBirthdate.slice(2);
            }
            // If there are more than 3 numbers eg. 02/4 -> and the 3rd number is larger than 3, or if its 3, and there's a 4th number that's larger than 1, insert a 0 before the 3rd digit since anything above 32 can't be a day value 
            if (unformattedBirthdate.length >= 4 && (parseInt(unformattedBirthdate.charAt(3)) > 3 || (parseInt(unformattedBirthdate.charAt(3)) === 3 && unformattedBirthdate.length > 4 && parseInt(unformattedBirthdate.charAt(4)) > 1))) {
                unformattedBirthdate = unformattedBirthdate.slice(0, 3) + '0' + unformattedBirthdate.slice(3);
            }
            // if we have more characters after where the last slash would go, or if the original input had a slash at the end, put the slash back
            if (unformattedBirthdate.length > 5 || (this.fields.birthdate.length === 6 && unformattedBirthdate.length === 5)) {
                unformattedBirthdate = unformattedBirthdate.slice(0, 5) + '/' + unformattedBirthdate.slice(5);
            }
            this.fields.birthdate = unformattedBirthdate;
            this.$forceUpdate(); // temp hack to get it to refresh
        },
        reset() {
            this.token = '';
            this.fields.birthdate = '';
            this.fields.SSN = '';
            this.fields.zip = '';
            this.fields.emailVerificationCode = '';
            this.fields.smsVerificationCode = '';
            this.resetNotifications();

            this.submitting = false
            this.oneOption = false;
            this.submitButtonText = this.$translate('firstComm.submitText');
            this.sendAgainButtonText = this.$translate('firstComm.sendAgainButtonText');

            this.forms = {
                verifyEmailCodeForm: {
                    $submitted: false,
                    $invalid: false,
                    emailVerificationCode: {
                        $error: {
                            required: false,
                        },
                    },
                },
                verifySmsCodeForm: {
                    $submitted: false,
                    $invalid: false,
                    smsVerificationCode: {
                        $error: {
                            required: false,
                        },
                    },
                },
                verifyPdivForm: {
                    $submitted: false,
                    $invalid: false,
                    birthdate: {
                        $error: {
                            required: false,
                            incomplete: false,
                            invalidData: false,
                            futureDate: false,
                        },
                        $invalid: false,
                    },
                    SSN: {
                        $error: {
                            required: false,
                            minlength: false,
                        },
                        $invalid: false,
                    },
                    zip: {
                        $error: {
                            required: false,
                            minlength: false,
                        },
                        $invalid: false,
                    },
                },
            }
            this.moveToState('options');
        },
        hidePrompt() {
            this.promptVisible = false;
        },
        close() {
            this.hidePrompt();
        },
        goToEstimateDetails(estimate) {
            if (estimate.providerDetails.requiresRedirectToBranded) {
                this.emitter.emit('redirect:tab', {
                    provider: estimate.providerDetails,
                    context: {
                        stateGo: {
                            to: 'SubAccountDetails',
                            toParams: {
                                providerId: estimate.providerDetails.id,
                                estimateId: estimate.id
                            }
                        }
                    }
                });
            } else {
                this.$router.push({
                    name: 'SubAccountDetails',
                    params: {
                        providerId: estimate.providerDetails.id,
                        estimateId: estimate.id
                    }
                });
            }
        },
        moveToState(stepName) {
            this.resetNotifications();
            this.currentStep = stepName;
        },
        handleVerifyError(resp) {
            if (resp === 'RATE_LIMIT_EXCEEDED' || (resp.errorCode && resp.errorCode === 'RATE_LIMIT_EXCEEDED')) {
                this.verifyNotifications.bruteForce = true;
                this.verifyNotifications.genericError = false;
            } else {
                this.verifyNotifications.genericError = true;
                this.verifyNotifications.bruteForce = false;
            }
        },

        submitEmailCode() {
            this.forms.verifyEmailCodeForm.$submitted = true;
            this.forms.verifyEmailCodeForm.$invalid = false;
            this.validateEmailCode();

            if (this.forms.verifyEmailCodeForm.$invalid || this.submitting) {
                return;
            }

            this.submitFindNew(this.fields.emailVerificationCode, '', '', '');
        },

        submitSmsCode() {
            this.forms.verifySmsCodeForm.$submitted = true;
            this.forms.verifySmsCodeForm.$invalid = false;
            this.validateSMSCode();

            if (this.forms.verifySmsCodeForm.$invalid || this.submitting) {
                return;
            }

            this.submitFindNew(this.fields.smsVerificationCode, '', '', '');
        },

        submitPdiv() {
            this.forms.verifyPdivForm.$submitted = true;
            this.forms.verifyPdivForm.$invalid = false;
            if (this.authOptions.pdivFields.guarantorDOB) {
                this.validateBirthdate();
            }
            if (this.authOptions.pdivFields.guarantorLast4SSN) {
                this.validateSSN();
            }
            if (this.authOptions.pdivFields.zip) {
                this.validateZip();
            }

            if (this.forms.verifyPdivForm.$invalid || this.submitting) {
                return;
            }

            var formattedBirthdate = this.$formatDate(this.fields.birthdate, 'YYYY-MM-DD', 'MM/DD/YYYY');

            this.submitFindNew('', this.fields.SSN, formattedBirthdate, this.fields.zip);
        },

        submitFindNew(verificationCode, guarantorLast4SSN, guarantorDOB, zip) {
            if (this.verifyInProgress) {
                return;
            }

            this.submitting = true;
            this.submitButtonText = this.$translate('firstComm.submittingText');

            this.resetNotifications();

            if (this.type === billType) {
                this.getFullstory.event('first-bill find-new started');
            } else if (this.type === estimateType) {
                this.getFullstory.event('first-estimate find-new started');
            }

            this.verifyInProgress = true;

            if (this.type === billType) {
                this.$store.dispatch('findNewBill', {token: this.token, verificationCode, guarantorLast4SSN, guarantorDOB, zip}).then(() => {
                    this.getFullstory.log('log', 'success first-bill find-new');

                    this.hidePrompt();
                    // Add a check that we have a current user before calling this
                    // LinkedAccountsService.fetchUnverified(true);
                    // Used later in the payment flow for logging
                    this.$store.commit('setIsFirstBill', true);
                    this.submitButtonText = this.$translate('firstComm.submitText');
                    this.submitting = false;
                    this.verifyInProgress = false;
                    this.promptVisible = false; // make sure to set the prompt as hidden so that we trigger the modal hide code which hides the overlay
                    //bill has been set as currentBill within billsService. send user toward the verify/payment flow.
                    this.$router.push({name: 'BillSummary'});
                })
                .catch((resp) => {
                    this.submitButtonText = this.$translate('firstComm.submitText');
                    this.submitting = false;
                    this.verifyInProgress = false;
                    this.getFullstory.log('error', 'first-bill find-new');
                    this.handleVerifyError(resp);
                });
            } else if (this.type === estimateType) {
                this.$store.dispatch('findNewEstimate', {token: this.token, verificationCode, guarantorLast4SSN, guarantorDOB, zip}).then((response) => {
                    this.getFullstory.log('log', 'success first-estimate find-new');

                    this.hidePrompt();

                    // Used later in the payment flow for logging
                    this.$store.commit('setIsFirstEstimate', true);
                    
                    var estimate = this.currentEstimate;
                    this.submitButtonText = this.$translate('firstComm.submitText');
                    this.submitting = false;
                    this.verifyInProgress = false;
                    this.promptVisible = false; // make sure to set the prompt as hidden so that we trigger the modal hide code which hides the overlay
                    if (!response.wasLinked) {
                        this.$router.push({
                            name: 'EstimateSummary',
                            params: {
                                providerId: estimate.providerDetails.providerId,
                                estimateId: estimate.estimateId,
                            },
                        });
                    } else {
                        this.goToEstimateDetails(estimate);
                    }
                })
                .catch((resp) => {
                    this.submitButtonText = this.$translate('firstComm.submitText');
                    this.submitting = false;
                    this.verifyInProgress = false;
                    this.getFullstory.log('error', 'first-estimate find-new');
                    this.handleVerifyError(resp);
                });
            }
        },
        sendCode(isResend) {
            if (this.codeSendInProgress) {
                return;
            }

            if (!isResend) {
                isResend = false;
            }

            this.codeNotSent = false;

            this.codeSendInProgress = true;

            let getCodeFunction;
            if (this.type === billType) {
                getCodeFunction = 'firstBillGetCode';
                
            } else if (this.type === estimateType) {
                getCodeFunction = 'firstEstimateGetCode';
            }
            if (getCodeFunction === undefined) {
                this.codeNotSent = true;
                this.codeSendInProgress = false;
                return;
            }
            this.$store.dispatch(getCodeFunction, this.token).then(() => {
                if (isResend) {
                    this.codeSent = true;
                    setTimeout(() => {
                        this.codeSent = false;
                    }, 5000);
                }
            }).catch(() => {
                this.codeNotSent = true;
                setTimeout(() => {
                    this.codeNotSent = false;
                }, 5000);
            }).finally(() => {
                this.codeSendInProgress = false;
            });
        },
    },
    created() {
        this.emitter.on('firstCommVerification:prompt', (args) => {
            this.reset();
            this.authOptions = args.options;
            this.authPossible = this.authOptions.canEmailAuth || this.authOptions.canPhoneAuth || this.authOptions.canPdivAuth;
            this.token = args.token;
            if (!this.isValidCommType(args.type)) {
                this.getFullstory.event('first-comm error Invalid type: ', {type: args.type});
                // TODO: throw an error here
            }
            this.type = args.type;
            if (this.authOptions.canPdivAuth) {
                // Backwards compatible, default fields to dob + ssn
                if (!this.authOptions.pdivFields) {
                    this.authOptions.pdivFields = {
                        guarantorDOB: true,
                        guarantorLast4SSN: true,
                        zip: false
                    };
                }

                //Just pdiv, skip to pdiv
                if (!this.authOptions.canEmailAuth && !this.authOptions.canPhoneAuth) {
                    this.oneOption = true;
                    this.moveToState('verify-pdiv');
                }
            } else {
                //Just sms, skip to sms
                if (!this.authOptions.canEmailAuth && this.authOptions.canPhoneAuth) {
                    this.sendCode(false);
                    this.oneOption = true;
                    this.moveToState('verify-sms');
                //Just email, skip to email
                } else if (this.authOptions.canEmailAuth && !this.authOptions.canPhoneAuth) {
                    this.sendCode(false);
                    this.oneOption = true;
                    this.moveToState('verify-email');
                }
            }

            this.providerId = this.authOptions.providerId;
            this.providerName = this.authOptions.providerName;

            if (this.type === billType) {
                this.optionsSubheader = this.$translate('firstBill.optionsSubheader', {providerName: this.authOptions.providerName});
                this.unableToAuth = this.$translate('firstBill.unableToAuth');
                this.verifyPdivHeader = this.$translate('firstBill.verifyPdivHeader', {patientFirstName: this.guarantorFirstName, providerName: this.authOptions.providerName});
                this.verifyEmailSubheader = this.$translate('firstBill.verifyEmailSubheader', {providerName: this.authOptions.providerName, emailAddress: this.authOptions.emailAddress});
                this.verifySmsSubheader = this.$translate('firstBill.verifySmsSubheader', {providerName: this.authOptions.providerName, phoneNumber: this.authOptions.phoneNumber});
            } else if (this.type === estimateType) {
                this.optionsSubheader = this.$translate('firstEstimate.optionsSubheader', {providerName: this.authOptions.providerName});
                this.unableToAuth = this.$translate('firstEstimate.unableToAuth');
                this.verifyPdivHeader = this.$translate('firstEstimate.verifyPdivHeader', {patientFirstName: this.guarantorFirstName, providerName: this.authOptions.providerName});
                this.verifyEmailSubheader = this.$translate('firstEstimate.verifyEmailSubheader', {providerName: this.authOptions.providerName, emailAddress: this.authOptions.emailAddress});
                this.verifySmsSubheader = this.$translate('firstEstimate.verifySmsSubheader', {providerName: this.authOptions.providerName, phoneNumber: this.authOptions.phoneNumber});
            }

            this.promptVisible = true;

            if (this.type === billType) {
                this.getFullstory.event('first-bill modal opened');
            } else if (this.type === estimateType) {
                this.getFullstory.event('first-estimate modal opened');
            }
        });
    },
    destroyed() {
        this.emitter.off('firstCommVerification:prompt');
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.first-bill-verification-modal .modal-content-container {
    padding: 35px 0 0;

    .modal-inner-content-wrapper {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: auto;

        .modal-inner-content-container {
            box-sizing: border-box;
            min-height: 100%;
            position: relative;
            padding: 0 15px 85px;
        }
    }

    .button-container {
        @include wider-than-phablet {
            margin-top: 30px;
        }

        button {
            margin-bottom: 1.5rem;
        }
    }

    .modal-subheader {
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;

        @include wider-than-phablet {
            font-size: 1.8rem;
        }
    }

    .verification-pdiv-section {
        .modal-subheader {
            @include wider-than-phablet {
                font-size: 2.2rem;
            }
        }

    }

    .logo-container {

		width: 100%;
		min-height:50px;
		text-align: center;
		margin-bottom: 3rem;

		img {
			max-width: 50%;
            max-height: 120px;
		}
	}
}


</style>