<template>
    <div class="vuetify patientapp-date-picker v-application">
        <v-date-picker
            v-bind="$attrs"
            :next-icon="nextIcon"
            :prev-icon="prevIcon"
            :elevation="elevation"
            :hide-actions="true"
            v-model="date"
            @update:modelValue="updateDate"
            :min="min"
            :max="max"
            :locale="locale"
            :color="color"
        ></v-date-picker>
    </div>
</template>
<script>
export default {
    name: 'BaseDatePicker',
    props: {
        nextIcon: {
            default: 'mdi-chevron-right'
        },
        prevIcon: {
            default: 'mdi-chevron-left'
        },
        elevation: {
            default: 1
        },
        min: String,
        max: String,
        modelValue: String,
    },
    data: () => ({
        date: null,
    }),
    computed: {
        brandingConfig() {
            return this.$store.getters.brandingConfig;
        },
        locale() {
            return this.$store.getters.language == 'es' ? 'es-us' : 'en-us';
        },
        color() {
            return this.brandingConfig ? this.brandingConfig.secondaryColor : this.$vuetify.theme.themes.light.colors.primary;
        }
    },
    methods: {
        updateDate() {
            let formattedDate = this.$formatDate(this.date, 'YYYY-MM-DD', null);
            this.$emit('update:modelValue', formattedDate);
        }
    },
    async mounted() {
        await this.$nextTick();
        if (this.modelValue) {
            this.date = new Date(this.modelValue + "T00:00:00");
        } else {
            this.date = new Date();
        }
    },
};
</script>

<style lang="scss">
@import '../styles/variables.scss';

.vuetify.patientapp-date-picker {
    display: flex;
    .v-date-picker {
        flex: 1;
    }
    .v-picker-title {
        .v-date-picker__title {
            font-size: 1.2rem;
        }
    }
    .v-picker__header {
        .v-date-picker-header__append button {
            display: none;
        }
        .v-date-picker-header__content {
            font-size: 2.4rem;
        }
    }
    .v-picker__body {
        .v-date-picker-controls {
            font-size: 1.6rem;
        }
        .v-date-picker-month__day {
            font-size: 1.2rem;
            button.v-btn {
                font-size: 1.2rem;
            }
        }
    }
    .v-picker__actions {
        button {
            display: none;
        }
    }
}
</style>