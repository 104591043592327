<template>
    <base-expansion-panel :key="newEcheckIndex" :selected="selected" class="payment-flow-method-step-new-method-new-echeck-card vuetify">
        <template #title>
            <div class="echeck-header">
                {{ $translate('payment.method.eCheckMethod') }}
            </div>
        </template>
        <template v-slot:expanded>
            <div class="echeck-content">
                <div class="horizontal-fields">
                    <base-text-field
                        v-model="echeck.firstName"
                        :label="$translate('labels.firstName')"
                        :error-messages="v$.echeck.firstName.$errors.map(e => e.$message)"
                        class="fs-block first-of-type first-name x2"
                        name="eCheckFirstName"
                        required
                        @blur="v$.echeck.firstName.$touch"
                    ></base-text-field>
                    <base-text-field
                        v-model="echeck.lastName"
                        :label="$translate('labels.lastName')"
                        :error-messages="v$.echeck.lastName.$errors.map(e => e.$message)"
                        class="fs-block last-name x2"
                        name="eCheckLastName"
                        required
                        @blur="v$.echeck.lastName.$touch"
                    ></base-text-field>
                </div>
                <base-text-field
                    v-model="echeck.routing"
                    :label="$translate('labels.routingNumber')"
                    :error-messages="v$.echeck.routing.$errors.map(e => e.$message)"
                    class="fs-block routing-number"
                    name="routing"
                    @keypress="onlyNumbers($event)"
                    maxlength="9"
                    required
                    @blur="v$.echeck.routing.$touch"
                    inputmode="decimal"
                ></base-text-field>
                <base-text-field
                    v-model="echeck.accountNumber"
                    :label="$translate('labels.accountNumber')"
                    :error-messages="v$.echeck.accountNumber.$errors.map(e => e.$message)"
                    class="fs-block account-number"
                    name="accountNumber"
                    @keypress="onlyNumbers($event)"
                    maxlength="25"
                    outlined
                    @blur="v$.echeck.accountNumber.$touch"
                    inputmode="decimal"
                ></base-text-field>
                <base-text-field
                    v-model="echeck.confirmAccountNumber"
                    :label="$translate('labels.accountNumberConfirm')"
                    :error-messages="v$.echeck.confirmAccountNumber.$errors.map(e => e.$message)"
                    class="fs-block confirm-account-number"
                    name="confirmAccountNumber"
                    ref="confirmAccountNumber"
                    @keypress="onlyNumbers($event)"
                    maxlength="25"
                    required
                    @blur="v$.echeck.confirmAccountNumber.$touch"
                    inputmode="decimal"
                ></base-text-field>
            </div>
            <payment-flow-billing-address v-model="billing" type="eCheck" ref="paymentFlowBillingAddress"></payment-flow-billing-address>
            <div class="horizontal-fields save-echeck vuetify">
                <base-checkbox
                    v-if="!hideSaveOption"
                    v-model="echeck.saveEcheck"
                    :label="$translate('labels.saveEcheck')"
                    class="fs-block save-echeck-checkbox"
                    name="saveEcheck"
                    ref="saveEcheck"
                ></base-checkbox>
            </div>
        </template>
    </base-expansion-panel>
</template>
<script>
import BaseSelect from './BaseSelect.vue';
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import http from '../utils/http';
import _ from 'lodash';
import { onlyNumbers, states } from './../utils/utils.js';
import BaseTextField from './BaseTextField.vue';
import BaseCheckbox from './BaseCheckbox.vue';
import useValidate from '@vuelidate/core';
import { required, minLength, sameAs, helpers } from '@vuelidate/validators';
import PaymentFlowBillingAddress from './PaymentFlowBillingAddress.vue';

export default {
    name: 'PaymentFlowMethodStepNewECheckCard',
    components: {
        BaseTextField,
        BaseExpansionPanel,
        BaseSelect,
        BaseCheckbox,
        PaymentFlowBillingAddress
    },
    props: {
        hideSaveOption: {
            type: Boolean,
            default: false,
        },
        isPaymentPlan: Boolean,
        selected: Boolean,
        newEcheckIndex: Number,
        modelValue: Object,
    },
    data: () => ({
        v$: useValidate(),
        bankName: '',
        invalidRouting: '',
        echeck: {
            firstName: '',
            lastName: '',
            routing: '',
            accountNumber: '',
            confirmAccountNumber: '',
            saveEcheck: true,
        },
        billing: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: ''
        },
        states: states
    }),
    validations() {
        return {
            echeck: {
                firstName: { required: this.requiredMsg },
                lastName: { required: this.requiredMsg },
                routing: {
                    required: this.requiredMsg,
                    validate: helpers.withMessage(this.$translate('errors.invalidRoutingNumber'), helpers.withAsync(this.validateRouting))
                },
                accountNumber: {
                    required: this.requiredMsg,
                    minLength: helpers.withMessage(
                        this.$translate('errors.minimumAccountNumber'),
                        minLength(4)
                    )
                },
                confirmAccountNumber: {
                    required: this.requiredMsg,
                    sameAs: helpers.withMessage(
                        this.$translate('errors.mismatchedAccountNumber'),
                        sameAs(this.echeck.accountNumber)
                    )
                }
            }
        }
    },
    computed: {
        requiredMsg() {
            return helpers.withMessage(this.$translate('errors.fieldRequired'), required);
        }
    },
    watch: {
        echeck: {
            handler() {
                this.updateModelValue();
            },
            deep: true 
        },
        bankName: {
            handler() {
                this.updateModelValue();
            } 
        },
        billing: {
            handler() {
                this.updateModelValue();
            },
            deep: true 
        },
        "billing.state": {
            handler(value) {
                if (typeof value == 'string') {
                    let state = this.states.find(state => {
                        return state.value == value;
                    });
                    this.billing.state = state;
                }
            }
        }
    },
    methods: {
        onlyNumbers: onlyNumbers,
        updateModelValue() {
            let newBilling = _.cloneDeep(this.billing);
            newBilling.state = (this.billing && this.billing.state) ? this.billing.state.value : null;
            this.$emit('update:modelValue', {
                ...this.echeck,
                bankName: this.bankName,
                billing: newBilling,
                valid: (!this.v$.echeck.$invalid && this.billing.valid)
            });
        },
        validateFields() {
            this.$refs.paymentFlowBillingAddress.validateFields();
            this.v$.$validate();
        },
        async validateRouting(value) {
            if (!value || value.length != 9 || value == '000000000') {
                this.bankName = '';
                return false;
            }
            var url = `paymentForms/routingNumber/${value}`;
            var resp = await http.get(url).then((resp) => {
                return (resp.hasData()) ? resp.getData() : [];
            }).catch((err) => {
                console.error("There was an error getting banking information", err);
            });
            if (resp && resp.bankName) {
                this.bankName = resp.bankName;
                return true;
            }
            this.bankName = '';
            return false;
        },
    },
    async mounted() {
        await this.$nextTick();
        if (this.modelValue) {
            Object.keys(this.modelValue).forEach((key) => {
                if ('billing' !== key) {
                    this.echeck[key] = this.modelValue[key];
                }
            });
            if (this.modelValue.billing) {
                Object.keys(this.modelValue.billing).forEach((key) => {
                    this.billing[key] = this.modelValue.billing[key];
                });
            }
        }
        if (this.isPaymentPlan) {
            this.echeck.saveEcheck = true;
        }
    }
};
</script>
<style lang="scss">
@import '../styles/vuetify-overrides.scss';
@import './../styles/variables.scss';
.payment-flow-method-step-new-method-new-echeck-card {
    .v-expansion-panel-text {
        @include wider-than-tablet {
            margin-left: 3.5rem;
            margin-right: 3.5rem;
        }
    }

    .echeck-content {
        padding-bottom: 3.4rem;
        color: $global-color-font-new;
    }

    h2 {
        color: $global-color-font-new;
        font-size: 1.4rem;
        line-height: 2rem;
        padding-left: 0.4rem;
        padding-bottom: 0.4rem;
    }

    .routing-number {
        margin-top: 0;
    }

    .horizontal-fields {
        display: flex;
        flex-flow: row wrap;
        .v-text-field {
            width: 50%;
        }
        .first-of-type {
            padding-right: 5px;
        }
    }
}
</style>