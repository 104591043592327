<template>
<div id="footer">
	<div class="footer-content">
		<div class="four columns footer-logo">
			<patientco-waystar-logo footer class="footer-text"></patientco-waystar-logo>
			<div class="footer-copyright">&copy; {{ currentYear }}</div>
		</div>

		<div class="four columns u-center footer-links">
			<div class="row">
				<div class="offset-by-four three columns u-center">
					<a href="https://www.patientco.com/about-us/" target="_blank">{{ $translate('links.whyPatientco') }}</a>
				</div>
				<div class="three columns u-center">
					<a href="https://www.waystar.com/patientco-privacy-policy/" target="_blank">{{ $translate('links.privacyPolicy') }}</a>
				</div>
				<div class="three columns u-center">
					<a href="https://www.waystar.com/patientco-terms-of-use/" target="_blank">{{ $translate('links.termsOfService') }}</a>
				</div>
			</div>
		</div>

		<div class="four columns">
            <div class="accepted-cards">
				<base-icon icon="custom:AmexSVG"></base-icon>
				<base-icon icon="custom:MastercardSVG"></base-icon>
				<base-icon icon="custom:DiscoverSVG"></base-icon>
				<base-icon icon="custom:VisaSVG"></base-icon>
				<base-icon icon="custom:ApplePaySVG"></base-icon>
				<base-icon icon="custom:GooglePaySVG"></base-icon>
            </div>
		</div>
	</div>
</div>
</template>

<script>
import BaseIcon from './BaseIcon.vue';
import PatientcoWaystarLogo from './PatientcoWaystarLogo.vue';

export default {
	name: 'StandardFooter',
	components: {
		BaseIcon,
		PatientcoWaystarLogo,
	},
    computed: {
        currentYear() {
            return (new Date()).getFullYear();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
.accepted-cards {
	display: block;
	margin: 3rem auto 2rem auto;
	text-align: center;

    @include wider-than-phablet {
		float: right;
		margin: 2rem 0 0 4.6rem;
		width: unset
	}

	@include wider-than-tablet {
		margin: 0;
	}

	svg {
		width: 3rem;
		margin-right: 0.58rem;
		vertical-align: text-top;

		&:last-of-type {
			margin-right: 0;
		}
	}

	#G_Pay_Acceptance_Mark {
		width: auto;
    	height: 36px;
    	margin-top: -7px;
    	margin-left: -10px;
	}
}
</style>