<template>
    <content-card class="vuetify bill-summary-cost-summary bill-summary-cost-summary-card" data-testid="bill-summary-cost-summary">
        <div class="bill-summary-header">
            <div>
                <div v-if="providerLocation" class="header-provider-location">{{ providerLocation }}</div>
                <div>
                    <div v-if="patientName">
                        <div class="header-services-for fs-block">{{ $translate('labels.servicesFor') }} {{ patientName }}</div>
                    </div>
                    <div v-if="accountNumber && $vuetify.display.lgAndUp" class="header-account-number" data-testid="bill-summary-header-account-number">
                        {{ accountNumberLabel + ': ' + formattedAccountNumber }}
                    </div>
                    <div v-else-if="accountNumber">
                        <span v-if="accountNumber" data-testid="bill-summary-header-account-number">{{ accountNumberLabel + ': ' }}<a @click="$emit('viewaccountinfo')" class="header-account-number vuetify">{{ formattedAccountNumber }}</a></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bill-summary-balance">
            <div @click="openDbuBottomSheet" v-if="isDbu" class="dbu">
                <div v-if="dbuUp">
                    <base-icon class="arrow-icon arrow-icon-up" icon="mdi-arrow-up"></base-icon>
                    <div class="dbu-balance-text">{{ $translate('dbuUpdate.balanceIncrease') }} <base-icon class="help-circle-icon" icon="mdi-help-circle"></base-icon></div>
                </div>
                <div v-if="!dbuUp">
                    <base-icon class="arrow-icon arrow-icon-down" icon="mdi-arrow-down"></base-icon>
                    <div class="dbu-balance-text">{{ $translate('dbuUpdate.balanceDecrease') }} <base-icon class="help-circle-icon" icon="mdi-help-circle"></base-icon></div>
                </div>
                <div class="dbu-last-statement-text">{{ $translate('dbuUpdate.lastStatementBalance') }} {{ $formatCurrency(billAmount) }}</div>
            </div>
            <div v-if="account.isBadDebt" class="balance-amount-due-label">{{ $translate('labels.balanceTransferred') }}</div>
            <div v-else class="balance-amount-due-label">{{ $translate('labels.accountBalance') }}</div>
            <div v-if="shouldHideBalance" class="balance-amount-due" :class="{ 'grayed-out': shouldGrayOut }">$-.--</div>
            <div v-else class="balance-amount-due" :class="{ 'grayed-out': shouldGrayOut }">{{ $formatCurrency(amountDue) }}</div>
            <div class="account-status">
                <financing-details :account="account" v-if="account.financedBy"></financing-details>
                <status-chip :account="account" v-else></status-chip>
                <base-icon v-if="shouldShowAccountStatusPrompt" @click="accountStatusBottomSheet = true" class="help-circle-icon vuetify" icon="mdi-help-circle"></base-icon>
                <div v-else-if="!hidePaymentPlanLink && shouldShowPaymentPlanPrompt" @click="viewPlanLink()" class="payment-plan-link">{{ $translate('actions.view') }}</div>
            </div>
            <div v-if="gatewayDown" class="gateway-down">
                {{ $translate('dialogs.gatewayDown') }}
            </div>
            <base-btn v-if="showFinancingRedirectButton" @click="$emit('redirecttofinancing')" class="payment-options-button button-bigger-text balance-btn v-button-primary" :x-large="$vuetify.display.lgAndUp" :width="$vuetify.display.lgAndUp ? 'unset' : '100%'" tabindex="1" :disabled="loadingFinancingRedirect" data-testid="financing-redirect">
                {{ $translate('actions.goToVendor', { financedBy }) }}
            </base-btn>
            <base-btn v-else-if="gatewayDown || showProviderDetailsButton" @click="$emit('gotoaccountssummary')" class="payment-options-button button-bigger-text balance-btn v-button-primary" :x-large="$vuetify.display.lgAndUp" :width="$vuetify.display.lgAndUp ? 'unset' : '100%'" tabindex="1" data-testid="gateway-down-view-dashboard">
                {{ $translate('actions.goToDashboard') }}
            </base-btn>
            <base-btn v-else-if="!financedBy" @click="$emit('viewpaymentoptions')" class="payment-options-button view-payment-options button-bigger-text balance-btn v-button-primary" :x-large="$vuetify.display.lgAndUp" :width="$vuetify.display.lgAndUp ? 'unset' : '100%'" tabindex="1" :disabled="disablePaymentButton" data-testid="account-details-balance-view-payment-options">
                {{ $translate('actions.viewPaymentOptions') }}
            </base-btn>
        </div>
        <div v-if="(billSummary && Object.keys(billSummary).length>0) || account.hasPendingBalance" class="bill-summary-cost-summary-breakdown data-grid">
            <div class="data-grid" :class="!servicesExpandable && !patientResponsibilityExpandable ? 'not-expandable-padding' : null">
                <div class="row" :class="patientResponsibilityExpandable || servicesExpandable ? 'expand-icon-padding' : null" v-for="(value, label) in billSummary" :key="label">
                    <div class="col-left col-left-padding">
                        <base-tooltip v-if="showTooltips" location="top" :header="$translate(('labels.' + label))" :tip="$translate(('glossary.' + label))">
                            <div class="label tipped">{{ $translate(('labels.' + label)) }}</div>
                        </base-tooltip>
                        <div v-else class="label">{{ $translate(('labels.' + label)) }}</div>
                    </div>
                    <div v-if="shouldHideBalance" class="col-right">{{ Number(value) >= 0 ? '' : '-' }}$-.--</div>
                    <div v-else class="col-right">{{ $formatCurrency(Number(value)) }}</div>
                </div>
                <div class="row patient-responsibility" :class="patientResponsibilityExpandable ? 'expandable' : null" v-if="insuranceBreakdown && Object.keys(insuranceBreakdown).length">
                    <div class="col-left" :class="!patientResponsibilityExpandable ? 'col-left-padding' : null">
                        <div v-if="servicesExpandable || patientResponsibilityExpandable" @click="expandPatientResponsibility" class="expand-icon-container">
                            <base-icon v-if="patientResponsibilityExpandable" :icon="patientResponsibilityExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"></base-icon>
                        </div>
                        <base-tooltip v-if="showTooltips" location="top" :header="$translate('labels.patientResponsibility')" :tip="$translate('glossary.patientResponsibility')">
                            <div @click="expandPatientResponsibility" :class="patientResponsibilityExpandable ? 'clickable' : null" class="tipped label patient-responsibility-text">
                                {{ $translate(('labels.patientResponsibility')) }}
                            </div>
                        </base-tooltip>
                        <div v-else @click="expandPatientResponsibility" :class="patientResponsibilityExpandable ? 'clickable' : null" class="label patient-responsibility-text">
                                {{ $translate(('labels.patientResponsibility')) }}
                            </div>
                        <base-icon v-if="hasInsuranceUncoveredAmount" class="alert" icon="mdi-alert-circle-outline"></base-icon>
                    </div>
                    <div class="col-right">{{ $formatCurrency(patientResponsibility?Number(patientResponsibility):0) }}</div>
                </div>
            </div>
            <base-divider v-if="!patientResponsibilityExpanded"></base-divider>
            <div class="data-grid expanded" v-if="patientResponsibilityExpanded">
                <div class="row" :class="label=='labels.patientResponsibility'?'patient-responsibility':''" v-for="(value, label, index) in insuranceBreakdown" :key="value">
                    <div class="col-left breakdown-label">
                        <base-tooltip v-if="showTooltips" location="top" :header="$translate(label)" :tip="$translate('glossary.' + label)">
                            <div class="label tipped">{{ $translate(label) }}</div>
                        </base-tooltip>
                        <div v-else class="label">{{ $translate(label) }}</div>
                    </div>
                    <div class="col-right breakdown-amount">{{ $formatCurrency(Number((value) ? value : 0.00)) }}</div>
                </div>
                <div class="notCovered" v-if="hasInsuranceUncoveredAmount">
                    <base-icon class="alert" icon="mdi-alert-circle-outline"></base-icon>
                    <div>
                        {{ $translate('labels.notCoveredExplanation') }}
                        <a v-if="uncoveredCarcCodes?.length" @click="showNotCoveredCarcs()" class="show-not-covered-carcs-link">
                            {{ $translate('actions.learnMore') }}
                        </a>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="row patient-responsibility" :key="this.patientResponsibility">
                    <div class="col-left breakdown-label">
                        <div>{{ $translate('labels.patientResponsibility') }}</div>
                    </div>
                    <div class="col-right breakdown-amount">{{ $formatCurrency(Number((this.patientResponsibility) ? this.patientResponsibility : 0.00)) }}</div>
                </div>
            </div>
        </div>
        <div v-if="sundaySkyProgramId" class="flex-container">
            <!--Display Sunday Sky Video Thumbnail-->
            <a @click="$emit('watchstatementoverview')">
                <img src="/videothumbnail.gif" alt="Video Thumbnail" class="video-thumbnail">
            </a>
            <div :class="patientResponsibilityExpandable || servicesExpandable ? 'expand-icon-padding' : null" class="link-wrapper">
                <a @click="$emit('watchstatementoverview')" class="cost-summary-video-link">
                    {{ $translate('actions.watchStatementOverview') }}
                </a>
                <br>
                <a @click="$emit('viewbill')" class="cost-summary-pdf-link">
                    {{ $translate('actions.viewStatmentPDF') }}
                </a>
            </div>
        </div>
        <!--If sunday sky is disabled, just show PDF link-->
        <a v-else @click="$emit('viewbill')" :class="patientResponsibilityExpandable || servicesExpandable ? 'expand-icon-padding' : null" class="cost-summary-pdf-link padding-left">
            <!--Disable PDF statement icon if video is available-->
            {{ $translate('actions.viewStatmentPDF') }}
        </a>
        <base-bottom-sheet v-model="dbuBottomSheet" @input="closeDbuBottomSheet">
            <div class="dbu-sheet">
                <div v-if="dbuUp" class="dbu-header"> 
                    {{ $translate('dbuUpdate.bottomSheetHeaderIncrease') }}
                </div>
                <div v-if="!dbuUp" class="dbu-header">
                    {{ $translate('dbuUpdate.bottomSheetHeaderDecrease') }}
                </div>
                <div class="dbu-body">
                    {{ $translate('dbuUpdate.bottomSheetBody')}} 
                </div>
                <div v-if="!disablePaymentButton && !showProviderDetailsButton && !showFinancingRedirectButton" class="dbu-payment-button">
                    <base-btn @click="$emit('viewpaymentoptions')" class="payment-options-button button-bigger-text balance-btn vuetify v-button-primary dbu-balance-btn" :x-large="$vuetify.display.lgAndUp" :disabled="disablePaymentButton" tabindex="1">
                        {{ $translate('actions.viewPaymentOptions') }}
                    </base-btn>
                </div>
                <div class="dbu-list-item">
                    <base-icon data-testid="view-bill-icon" icon="mdi-file-document-outline"></base-icon>
                    <div class="dbu-list-item-value">
                        <a data-testid="view-bill-link" @click="$emit('viewbill')">{{ $translate('actions.viewLastStatement') }}</a>
                    </div>
                </div>
                <div v-if="providerCanMessage" class="dbu-list-item">
                    <base-icon data-testid="compose-message-icon" icon="mdi-email-outline"></base-icon>
                    <div class="dbu-list-item-value">
                        <a data-testid="compose-message-link" @click="$emit('composemessage')">{{ $translate('actions.sendMessage') }}</a>
                    </div>
                </div>
            </div>
        </base-bottom-sheet>
        <base-bottom-sheet v-model="accountStatusBottomSheet" class="bottom-sheet account-status-bottom-sheet" content-class="account-status-sheet">
            <div class="account-status-header">{{ accountStatusHeader }}</div>
            <div class="account-status-body">{{ accountStatusBody }}</div>
        </base-bottom-sheet>
        <carc-descriptions-dialog
            v-if="uncoveredCarcCodes?.length"
            :carcCodes="uncoveredCarcCodes"
            ref="carcDescriptionsDialog">
        </carc-descriptions-dialog>
    </content-card>
</template>

<script>
import { MoneyUtils } from './../utils/utils.js';
import Account from './../models/Account';
import _ from 'lodash';
import ContentCard from './ContentCard.vue';
import FinancingDetails from './FinancingDetails.vue';
import StatusChip from './StatusChip.vue';
import SundaySkyPlayer from './SundaySkyPlayer.vue';
import BaseDivider from './BaseDivider.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseIcon from './BaseIcon.vue';
import BaseBtn from './BaseBtn.vue';
import BaseTooltip from './BaseTooltip.vue';
import CarcDescriptionsDialog from './CarcDescriptionsDialog.vue';

export default {
    name: 'BillSummaryCostSummary',
    components: {
        CarcDescriptionsDialog,
        ContentCard,
        StatusChip,
        SundaySkyPlayer,
        FinancingDetails,
        BaseDivider,
        BaseBottomSheet,
        BaseIcon,
        BaseBtn,
        BaseTooltip,
    },
    props: {
        amountDue: Number,
        dueDate: String,
        providerLocation: String,
        patientName: String,
        accountNumber: String,
        isDbu: Boolean,
        billAmount: Number,
        providerCanMessage: Boolean,
        account: Account,
        // Show payment button but disable it
        disablePaymentButton: {
            type: Boolean,
            default: false,
        },
        // Instead of payment button, show link to home
        showProviderDetailsButton: {
            type: Boolean,
            default: false,
        },
        // Instead of payment button, show link to ClearBalance
        showFinancingRedirectButton: {
            type: Boolean,
            default: false,
        },
        financedBy: String,
        loadingFinancingRedirect: {
            type: Boolean,
            default: false,
        },
        hidePaymentPlanLink: {
            type: Boolean,
            default: false,
        },
        sundaySkyProgramId: String,
    },
    data: () => ({
        accountStatusBottomSheet: false,
        dbuBottomSheet: false,
        patientResponsibilityExpanded: false,
        billSummary: {},
        patientResponsibility: null,
        insuranceBreakdown: {},
        hasInsuranceUncoveredAmount: false,
        showTooltips: false,
    }),
    computed: {
        eCheckProcessingAvailable() {
            return this.$store.getters.eCheckProcessingAvailable;
        },
        cardProcessingAvailable() {
            return this.$store.getters.cardProcessingAvailable;
        },
        financingProcessingAvailable() {
            return this.$store.getters.financingProcessingAvailable;
        },
        currentBill() {
            return this.$store.getters.currentBill;
        },
        gatewayDown() {
            // the gateway status array in the response is empty
            if (!this.eCheckProcessingAvailable && !this.cardProcessingAvailable && !this.financingProcessingAvailable) {
                return true;
            }
            return false;
        },
        dbuUp() {
            var diff = MoneyUtils.subtract(this.billAmount, this.amountDue);
            return diff < 0;
        },
        shouldHideBalance() {
            return this.account.hasPendingBalance;
        },
        shouldGrayOut() {
            return this.shouldHideBalance || !this.account.amountDue || this.account.isBadDebt || this.account.amountDue < 0 || !this.account.provider.active;
        },
        accountStatusHeader() {
            const accountStatus = this.account.getStatus();
            if ('PROVIDER_INACTIVE' === accountStatus) {
                return this.$translate('accountStatusSheets.providerInactiveHeader');
            }
            // if ('REFUND_ELIGIBLE' === accountStatus) {
            //     return this.$translate('accountStatusSheets.refundEligibleHeader');
            // }
            if ('BAD_DEBT' === accountStatus) {
                return this.$translate('accountStatusSheets.badDebtHeader');
            }
            return null;
        },
        accountStatusBody() {
            const accountStatus = this.account.getStatus();
            if ('PROVIDER_INACTIVE' === accountStatus) {
                return this.$translate('accountStatusSheets.providerInactiveBody');
            }
            // if ('REFUND_ELIGIBLE' === accountStatus) {
            //     return this.$translate('accountStatusSheets.refundEligibleBody');
            // }
            if ('BAD_DEBT' === accountStatus) {
                return this.$translate('accountStatusSheets.badDebtBody');
            }
            return null;
        },
        formattedAccountNumber() {
            const maxLen = this.$vuetify.display.lgAndUp ? 25 : 15;
            if (this.accountNumber && this.accountNumber.length <= maxLen) {
                return this.accountNumber;
            } else if (this.accountNumber) {
                return `${this.accountNumber.substring(0, maxLen)}...`;
            }
            return null;
        },
        shouldShowAccountStatusPrompt() {
            return ['PROVIDER_INACTIVE', /* 'REFUND_ELIGIBLE', */ 'BAD_DEBT'].includes(this.account.getStatus());
        },
        shouldShowPaymentPlanPrompt() {
            return this.account.canUpdatePaymentPlanMethod() && ['PAYMENT_PLAN_INPROGRESS', 'PAYMENT_PLAN_HELD'].includes(this.account.getStatus());
        },
        servicesExpandable() {
            return this.$store.getters.servicesExpandable;
        },
        patientResponsibilityExpandable() {
            return this.$store.getters.patientResponsibilityExpandable;
        },
        uncoveredCarcCodes() {
            return this.$store.getters.billUncoveredCarcCodes;
        },
        accountNumberLabel() {
            return this.$store.getters.accountNumberLabel;
        },
    },
    methods: {
        showNotCoveredCarcs() {
            if (this.$refs && this.$refs.carcDescriptionsDialog) {
                this.$refs.carcDescriptionsDialog.open();
            }
        },
        closeDbuBottomSheet() {
            this.dbuBottomSheet = false;
        },
        openDbuBottomSheet() {
            this.dbuBottomSheet = true;
        },
        viewPlanLink() {
            this.$router.push({
                name: 'PlanReceipt',
                params: {
                    providerId: this.account.provider.id,
                    planId: this.account.currentPlan.paymentPlanId,
                },
            });
        },
        expandPatientResponsibility() {
            if (this.patientResponsibilityExpandable) {
                this.patientResponsibilityExpanded = !this.patientResponsibilityExpanded;
            }
        },
        calculateEOB() {
            this.showTooltips = false;
            this.billSummary = {};
            this.patientResponsibility = null;
            this.insuranceBreakdown = {};
            this.hasInsuranceUncoveredAmount = false;
            if (this.currentBill && this.currentBill.subAccounts && this.currentBill.subAccounts.length) {
                let subAccounts = this.currentBill.subAccounts;
                let detailLines = subAccounts.flatMap((subAccount) => {
                    if (subAccount.detailLines !== undefined && subAccount.detailLines.length != 0 && Object.keys(subAccount.detailLines).length !== 0) {
                        return subAccount.detailLines;
                    }
                }).filter(n => n);
                if (Object.keys(detailLines).length) {
                    this.showTooltips = true;
                    let summary = {};
                    let breakdown = {};
                    summary.totalCharges = 0;
                    summary.adjustments = 0;
                    summary.insuranceCoverage = 0;
                    summary.patientPayments = 0;
                    detailLines.forEach((detailLine) => {
                        summary.totalCharges = detailLine.totalCharge ? (Number(detailLine.totalCharge) + summary.totalCharges) : summary.totalCharges;
                        summary.adjustments = detailLine.providerAdjustment ? (Number(detailLine.providerAdjustment) + summary.adjustments) : summary.adjustments;
                        summary.adjustments = detailLine.insuranceAdjustment ? (Number(detailLine.insuranceAdjustment) + summary.adjustments) : summary.adjustments;
                        summary.insuranceCoverage = detailLine.insurancePaid ? (Number(detailLine.insurancePaid) + summary.insuranceCoverage) : summary.insuranceCoverage;
                        summary.patientPayments = detailLine.patientPaid ? (Number(detailLine.patientPaid) + summary.patientPayments) : summary.patientPayments;
                        if (detailLine.carcCodes) {
                            detailLine.carcCodes.forEach((carc) => {
                                let code = 'carc.default';
                                if (['1','2','3'].includes(carc.carcCode)) {
                                    code = 'carc.'+carc.carcCode;
                                }
                                if (!breakdown[code]) {
                                    breakdown[code] = 0;
                                }
                                breakdown[code] += carc.amount ? (Number(carc.amount)) : 0;
                            });
                        }
                    });
                    this.patientResponsibility = summary.totalCharges + summary.adjustments + summary.insuranceCoverage;
                    this.billSummary = summary;
                    this.insuranceBreakdown = {
                        "carc.3": breakdown["carc.3"],
                        "carc.1": breakdown["carc.1"],
                        "carc.2": breakdown["carc.2"],
                        "carc.default": breakdown["carc.default"],
                    };
                    this.hasInsuranceUncoveredAmount = this.insuranceBreakdown['carc.default'] ? true : false;
                    return;
                }
            }
            if (this.currentBill && this.currentBill.summary && Object.keys(this.currentBill.summary).length) {
                let billSummary = this.currentBill.summary;
                if (billSummary.serviceCharges || billSummary.otherAdjustments || billSummary.insuranceContribution) {
                    let summary = {};
                    summary.totalCharges = billSummary.serviceCharges ? Number(billSummary.serviceCharges) : 0;
                    summary.adjustments = billSummary.otherAdjustments ? Number(billSummary.otherAdjustments) : 0;
                    summary.insuranceCoverage = billSummary.insuranceContribution ? Number(billSummary.insuranceContribution) : 0;
                    this.patientResponsibility = summary.totalCharges + (summary.adjustments + summary.insuranceCoverage);
                    this.billSummary = summary;
                }
                return;
            }
        }
    },
    mounted() {
        this.calculateEOB();
    }
};
</script>

<style lang="scss">
@import './../styles/variables.scss';
.bill-summary-cost-summary .content-card-content {
    display: flex;
    flex-direction: column;
    color: $global-color-font-new !important;

    .grayed-out {
        color: $global-color-greyed-out;
    }

    // mobile specific
    padding: 1.6rem 2.4rem;
    &.vuetify.bill-summary-cost-summary-card {
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 1.6rem;
        margin-right: 1.6rem;
    }

    @include wider-than-tablet {
        padding: 3.8rem;
    }

    .account-status {
        display: flex;
        justify-content: center;
        margin-bottom: 2.8rem;
        .help-circle-icon {
            color: #1867C0;
            margin-left: 0.8rem;
            cursor: pointer;
        }
        .payment-plan-link {
            color: $global-color-link-new;
            text-decoration: underline;
            cursor: pointer;
            font-size: 1.4rem;
            margin-left: 1rem;
            letter-spacing: 0.025rem;
            @include wider-than-tablet {
                font-size: 1.6rem;
                margin-left: 1.5rem;
            }
        }
    }

    .bill-summary-header {
        display: flex;
        justify-content: space-between;

        // mobile specific
        flex-direction: row;
        margin-bottom: 1rem;
        align-items: flex-start;

        .header-provider-location {
            font-size: 1.8rem;
            font-weight: 500;
            max-width: 30rem;
            letter-spacing: 0.016rem;
            line-height: 2.4rem;
            text-align: left;
            @include wider-than-tablet {
                font-size: 2.4rem;
                letter-spacing: 0.018rem;
            }
        }
        .header-account-number,
        .header-services-for  {
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 0.025rem;
            margin-top: 0.4rem;
            @include wider-than-tablet {
                font-size: 1.6rem;
                line-height: 2.4rem;
                letter-spacing: 0.014rem;
            }
        }
    }

    .bill-summary-balance {
        display: flex;
        flex-direction: column;
        margin-bottom: 2.5rem;

        .balance-amount-due {
            text-align: center;
            font-size: 4.8rem;
            font-weight: 500;
            @include wider-than-tablet {
                font-size: 7.2rem;
            }
        }

        .balance-amount-due-label {
            text-align: center;
            font-size: 1.6rem;
            line-height: 2.4rem;
            letter-spacing: 0.014rem;
            @include wider-than-tablet {
                font-size: 2.4rem;
            }
        }

        .gateway-down {
            text-align: center;
        }

        .dbu {
            text-align: center;

            :hover {
                cursor: pointer;
            }

            .dbu-balance-text {
                font-size: 1.4rem;
                letter-spacing: 0.01rem;
                font-weight: 500;
                line-height: 1.6rem;
            }

            .dbu-last-statement-text {
                font-size: 1.2rem;
                letter-spacing: 0.04rem;
                font-weight: 400;
                line-height: 1.6rem;
            }

            .help-circle-icon > .v-icon__svg{
                height: 1.4rem;
                width: 1.4rem;
            }
        }
    }

    .bill-summary-cost-summary-breakdown-extra-padding {
        padding-bottom: 0.9rem;
    }

    .bill-summary-cost-summary-breakdown {
        text-align: left;
        font-size: 1.4rem;
        letter-spacing: 0.025rem;

        @include wider-than-tablet {
            font-size: 1.6rem;
        }

        .data-grid {
            &.not-expandable-padding {
                padding-left: 2.4rem;
            }
        }

        &.data-grid .row {
            flex-wrap: wrap;
            min-height: 4rem;
            overflow-y: auto;

            .col-left {
                display: flex;
                align-items: center;
                width: 100%;

                .label {
                    &.tipped {
                        text-decoration: underline dotted;
                        &:hover {
                            background: $global-color-body-bg;
                            border-radius: 5px;
                        }
                    }
                    padding: .6rem;

                    
                }

                .alert {
                    font-size: 1.75rem !important;
                    color: red !important;
                }
                
                .expand-icon-container {
                    height: 4rem;
                    width: 4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    transition-property: background-color;
                    transition-duration: .2s;

                    &:hover:not(:empty) {
                        cursor: pointer;
                        background: $global-color-body-bg;
                    }

                    &:active:not(:empty) {
                        background-color: #9e9e9e;
                    }

                    i.mdi-chevron-up, i.mdi-chevron-down {
                        margin: auto;
                        color: $global-color-font-new !important;
                    }
                }

                .patient-responsibility-text {
                    cursor: pointer;
                    transition-property: background-color;
                    transition-duration: .2s;
                    width: fit-content;

                    &:hover {
                        cursor: pointer;
                    }

                    &.clickable:active {
                        background-color: #9e9e9e;
                    }
                }
            }

            .col-right {
                display: flex;
                flex: 1;
                padding-right: 2.4rem;
                align-items: center;
                justify-content: flex-end;
            }

            &.patient-responsibility {
                &.expandable {
                    &:not(.expand-icon-padding) {
                        padding-left: 0;
                    }
                }
                font-weight: 500;
            }

            &.expand-icon-padding {
                padding-left: 4rem;
            }
        }

        .data-grid .divider {
            margin: 0.8rem 2.4rem 0.8rem 0;
            border-top: solid 1px #DCDCDC;
        }

        .base-divider {
            margin: 16px 0px;
            border-top: solid 2px #DCDCDC;
        }

        .row.expandable {
            i {
                margin-top: -0.3rem;
            }
        }
        .expanded {
            font-size: 1.4rem;
            background: $global-color-body-bg;
            padding: 1.5rem 2.5rem 0.9rem 5.7rem;
            margin-bottom: 0.9rem;
            border-radius: 5px;

            .row {
                padding-left: 0;
                padding-right: 0;
                min-height: 3.4rem;

                .col-left {
                    .label {
                        padding: .6rem;
                        &.tipped {
                            text-decoration: underline dotted;
        
                            &:hover {
                                background-color: #DCDCDC;
                                border-radius: 5px;
                            }
                        }
                    }
                }

                &.patient-responsibility {
                    padding-left: .6rem;
                }
            }

            .notCovered {
                margin-top: 4px;
                margin-right: 2.4rem;
                padding: 0.8rem;
                background-color: $global-color-warning-light;
                border-radius: 5px;
                flex: 100%;
                display: flex;
                .alert {
                    flex: 0;
                    font-size: 1.75rem !important;
                    color: red !important;
                    padding-left: 0.5rem;
                }
                div {
                    padding-left: 1em;
                    flex: 1;
                }
            }
        }
    }

    .payment-options-button {
        margin-top: 0;
        margin-bottom: 2.4rem;
        align-self: center;
        padding: 1rem 2.6rem !important;

        @include wider-than-tablet {
            padding: 1.8rem 10rem 3.5rem 10rem !important;
        }
    }
}
.cost-summary-pdf-link, .cost-summary-video-link {
    font-size: 1.4rem;
    letter-spacing: 0.029rem;
    line-height: 1.8rem;
    margin-bottom: 0.4rem;
    text-decoration: underline;
    padding-left: 0.6rem;
    @include wider-than-tablet {
        font-size: 1.6rem;
        line-height: 3rem;
    }
    &.padding-left {
        margin-left: 2.4rem;
    }

    &.expand-icon-padding {
        margin-left: 4rem;
    }
}

.dbu-sheet {
    padding: 2.45rem;

    .dbu-header {
        padding-bottom: 1.5rem;
        text-align: left;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.015rem;
        color: $global-color-font-new;
    }

    .dbu-body {
        text-align: left;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.05rem;
        color: $global-color-font-new;
        padding-bottom: 1rem;
    }

    .dbu-payment-button {
        display: flex;
        justify-content: center;
        padding-bottom: 4rem;

            .dbu-balance-btn {
                height: 4.1rem;
                width: 26.4rem;
            }
    }

    .dbu-list-item {
        display: flex;
        align-items: center;
        margin-bottom: 1.3rem;
        .v-icon {
            margin-right: 2.5rem;
        }
        .dbu-list-item-value > a {
            font-size: 1.6rem;
            line-height: 2.4rem;
            letter-spacing: 0.014rem;
            text-decoration: underline;
            font-weight: 400;
        }
    }
}

.video-thumbnail {
    height: 58px;
    width: 100px;
    @include wider-than-tablet {
        height: 88px;
        width: 150px;
    }
}

.link-wrapper {
    margin-left: 1.4rem;
    margin-top: 0.4rem;
    text-align: left;
    @include wider-than-tablet {
        margin-left: 1.4rem;
        margin-top: 1.6rem;
        text-align: left;
    }

    &.expand-icon-padding {
        margin-left: 1.6rem;
    }
}

.flex-container {
    display: flex;

}
.account-status-header {
    padding: 3.2rem;
    padding-bottom: 0rem;
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1.2rem;
    @include wider-than-tablet {
        font-size: 1.6rem;
    }
}
.account-status-body {
    padding: 3.2rem;
    padding-top: 0;
    font-size: 1.6rem;
    @include wider-than-tablet {
        font-size: 1.8rem;
    }
}
</style>