<template>
<div id="quick-pay">
  <StandardHeader :showLoggedOutMobileNav=true></StandardHeader>
  <div id="app-wrap" class="vuetify quick-pay">
    <div class="container">
      <div class="header-persistent-content left">
        <!-- negative tabindex to prevent accidental navigation home inside paymentflow -->
        <div tabindex="-1" class="header-home-link" @click="goHome()"> 
          <ProviderLogo :providerId="providerId" :altText="providerName"></ProviderLogo>
        </div>
      </div>
      <div class="header-persistent-content right">
        <base-btn
            @click="needHelpBottomSheet = true"
            class="need-help-sheet-btn no-capitalization"
            variant="outlined"
        >{{ $translate('labels.needHelp') }}</base-btn>
      </div>
    </div>
    <div id="main-content-v2">
      <div id="left">
        <div class="fancy-background">
          <div class="amount-due-label">{{ $translate('quickPay.amountDue') }}</div>
          <div class="amount-due">{{  $formatCurrency(amountDue) }}</div>
          <div class="status-chip vuetify">
            <base-chip color="#1867C0">
              {{ $translate('statusChips.dueDate', { date: $formatDate(dueDate, 'MM/DD/YYYY') }) }}
            </base-chip>
          </div>
        </div>
      </div>
      <div id="right">
        <div class="salutation">{{ $translate('quickPay.salutation', {name:patientName}) }}</div>
        <div class="explanation">
          {{ $translate('quickPay.explanation') }}
          <span class="provider-name">{{ providerName }}</span>
          <a color="primary" class="view-bill-details" @click="verifyFirstCommUser">
            {{ $translate('quickPay.viewBillDetails') }}
          </a>
        </div>
        <!-- sub actions - floating contact and login links -->
        <div class="quickpay-actions">
          <base-btn
            @click="payFullAmount()"
            :disabled="noPaymentDue"
            class="full-payment v-button-primary no-capitalization vuetify"
            variant="outlined">
            {{ $translate('quickPay.payFullAmount') }}
          </base-btn>
          <base-btn
            @click="payPartialAmount()"
            :disabled="noPaymentDue"
            class="partial-payment no-capitalization vuetify"
            variant="outlined">
            {{ $translate('quickPay.payPartialAmount') }}
          </base-btn>
        </div>
      </div>
    </div>
  </div>
  <base-bottom-sheet v-model="needHelpBottomSheet" content-class="quick-pay-need-help-sheet need-help-sheet">
        <card-header
            :title="$translate('labels.needHelp')"
            icon="mdi-hospital-box-outline"
            class="sheet-card-header"
        ></card-header>
        <need-help
            :provider="currentProvider"
        ></need-help>
    </base-bottom-sheet>
</div>
</template>

<script>
import Account from './../models/Account';
import Provider from './../models/Provider';
import StandardFooter from './../components/StandardFooter.vue';
import BaseDialog from './../components/BaseDialog.vue';
import SundaySkyPlayer from './../components/SundaySkyPlayer.vue';
import BaseBtn from '../components/BaseBtn.vue';
import BaseChip from '../components/BaseChip.vue';
import BaseBottomSheet from '../components/BaseBottomSheet.vue';
import NeedHelp from '../components/NeedHelp.vue';
import CardHeader from '../components/CardHeader.vue';

import StandardHeader from '../components/StandardHeader.vue';
import ProviderLogo from '../components/ProviderLogo.vue';

export default {
  name: 'QuickPay',
  components: {
    BaseBottomSheet,
    BaseBtn,
    BaseChip,
    BaseDialog,
    CardHeader,
    NeedHelp,
    ProviderLogo,
    StandardFooter,
    StandardHeader,
    SundaySkyPlayer
  },
  data() {
    return {
      accountId: null,
      amountDue: 0,
      dueDate: new Date(),
      needHelpBottomSheet: false,
      patientName: null,
      providerName: null,
      providerId: null,
    }
  },
  computed: {
    brandingConfig() {
      return this.$store.getters.brandingConfig;
    },
    currentBill() {
      return this.$store.getters.currentBill;
    },
    currentProvider() {
      return this.$store.getters.currentProvider;
    },
    currentAccount() {
      return this.$store.getters.currentAccount;
    },
    currentPaymentData() {
      return this.$store.getters.currentPaymentData;
    },
    authOptionsBill() {
      return this.$store.getters.authOptionsBill;
    },
    firstBillToken() {
      return this.$store.getters.firstBillToken;
    },
    noPaymentDue(){
      return parseFloat(this.amountDue) <= 0;
    },
    isLoggedIn() {
      return Boolean(this.$store.getters.currentUser);
    },
  },
  methods: {
    goHome() {
      this.$router.push({name: 'ProvidersSummary'}); // will go to login page if logged out
    },
    needHelp() {
      let needHelpSrc = 'assets/images/needhelp.png';
      if (this.brandingConfig && this.brandingConfig.needHelpImage) {
          needHelpSrc = this.brandingConfig.needHelpImage;
      } else if (this.brandingConfig) {
          needHelpSrc = 'assets/images/needhelpbranded.png';
      }
      this.emitter.emit('simpleModal:showPrompt',{
          header: this.$translate('landing.needHelpHeader'),
          subcontent: this.$translate('landing.needHelpContent') +
              '<br /><br /><img class="needhelp" src="' + needHelpSrc + '"/>',
          actions: [{
              label: this.$translate('actions.close'),
              includeClasses: 'button-primary'
          }]
      });
    },
    payFullAmount() {
      this.$store.commit('setIsGuestPay', true);
      this.$store.commit('setIsQuickPay', true);
      this.$store.commit('setQuickPayType', 'CB');
      this.$router.push({
          name: 'GuestAccountPaymentFlowAmountStep',
          params: {
              accountId: this.accountId,
              providerId: this.providerId
          },
      });
    },
    payPartialAmount() {
      this.$store.commit('setIsGuestPay', true);
      this.$store.commit('setIsQuickPay', true);
      this.$store.commit('setQuickPayType', 'O');
      this.$router.push({
          name: 'GuestAccountPaymentFlowAmountStep',
          params: {
              accountId: this.accountId,
              providerId: this.providerId
          },
      });
    },
    verifyFirstCommUser() {
      this.emitter.emit("firstCommVerification:prompt", {
        'type': 'bill',
        'options': this.authOptionsBill,
        'token': this.firstBillToken,
      });
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.goHome();
    }
    this.$store.dispatch('showLoadingIfNecessary');
    let currentPaymentData = JSON.parse(window.sessionStorage.getItem("currentPaymentData"));
    // Get minimum data required to go through payment flow
    if (!currentPaymentData) {
      let authOptions = this.authOptionsBill;
      this.providerName = authOptions?.providerName;
      this.patientName = authOptions?.guarantorName;
      this.amountDue = authOptions?.billAmountDue;
      this.dueDate = new Date(authOptions?.billDueDate);
      this.accountId = authOptions?.id;
      this.providerId = authOptions?.providerId;
      let secureCode = authOptions?.secureCode;
      let active = authOptions?.active;
      let features = authOptions?.features;
      let billingPhoneNumber = authOptions?.billingPhoneNumber;
      let websites = authOptions?.websites;
      let additionalPhones = authOptions?.additionalPhones;
      let paymentMaxScheduleDays = authOptions?.paymentMaxScheduleDays;
      let provider = new Provider({
        id: this.providerId,
        name: this.providerName,
        active: active,
        features: features,
        billingPhoneNumber: billingPhoneNumber,
        websites: websites,
        additionalPhones: additionalPhones,
        paymentMaxScheduleDays: paymentMaxScheduleDays
      });
      let billSource = {
        hasFinancing: false,
        paymentOptions: [
          { type: 'CB', amount:  parseFloat(this.amountDue) },
          { type: 'O' }
        ],
        providerDetails: provider,
        accountInfo: {
          patientName: this.patientName
        }
      };
      let account = new Account({
        accountId: this.accountId,
        dueDate: this.dueDate,
        amountDue: parseFloat(this.amountDue),
        secureCode: secureCode,
        bills: [ billSource ]
      }, provider);
      this.$store.commit('setCurrentProvider', provider);
      this.$store.commit('setCurrentAccount', account);
      this.$store.commit('setCurrentPaymentData', {provider: provider, account: account, estimate: null, subAccount: null});
      sessionStorage.setItem("currentPaymentData", JSON.stringify(this.currentPaymentData));
    } else {
      let provider = new Provider(currentPaymentData.provider);
      let account = new Account(currentPaymentData.account, provider);
      this.$store.commit('setCurrentProvider', provider);
      this.$store.commit('setCurrentAccount', account);
      this.$store.commit('setCurrentPaymentData', {provider: provider, account: account, estimate: null, subAccount: null});
      this.providerName = this.currentProvider.name;
      this.patientName = this.currentAccount.bills[0].accountInfo.patientName;
      this.amountDue = this.currentAccount.amountDue;
      this.dueDate = this.currentAccount.dueDate;
      this.accountId = this.currentAccount.accountId;
      this.providerId = this.currentProvider.id;
    }
  },
};
</script>
<style lang="scss">
@import './../styles/variables.scss';
#quick-pay{
  // mobile-specific styles
  @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    span.header{
      display:block;
      width: 100%;
      height: 54px;
      margin-bottom: 15px;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;

      .right{
        display: flex;
        justify-content: flex-end; 
      }

      .header-home-link{
        height: initial;
        visibility: visible;
      }

      img{
          max-height: 65px;
          max-width: 200px;
      }

      button.need-help-sheet-btn{
        font-size: 1.4rem;
      }
    }

    #main-content-v2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 
      width: 100%;
      max-width: 360px !important;
      width: 375px;
      margin-bottom: 120px;

      #left, #right {
        display: contents; // bypass containers to allow flexbox children to be arranged
      }

      .fancy-background {
        background-image: url("../../public/assets/images/corner-fold-page.png");
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        width: 100%;
        height: 650px;
        padding-top: 112px;
        min-width: 320px;
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        order: 3; 

        .amount-due {
            font-size: 4rem;
            font-weight: 500;
            line-height: 4rem;
            padding-bottom: 14px;
        }

        .amount-due-label {
            font-size: 1.6rem;
        }

        .v-chip__content  {
          text-transform: initial;
          letter-spacing: initial;
          font-weight: normal;
          font-size: 1.4rem;
          padding:0 15px;
        }
      }

      .salutation {
        order: 1; 
        width: 100%;
        padding: 0 3.4rem;
        font-size: 2.4rem;
        margin-top: 2rem;
      }

      .explanation {
        order: 2; 
        width: 100%;
        padding: 0 3.4rem;
        font-size: 1.6rem;
        margin-top: 0.7rem;
        
        .provider-name{
          font-weight: 700;
        }

        .view-bill-details {
          display: block;
          margin: .6rem 0;
          font-size: 1.6rem;
        }
      }

      .quickpay-actions {
        order: 4; 
        margin-top: -390px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 100%;
        padding: 0 54px;

        button.base-btn {
          font-size: 1.4rem;
          font-weight: 400;
          padding: 0;
          margin-bottom: 10px;
          width: 100%;
          height: 40px;
          &.partial-payment {
            border-color: $global-color-secondary;
            color: $global-color-secondary;
          }
        }
      }
    }
  }

  @include wider-than-tablet{
    .header{
      display: none;
    }

    #app-wrap { 
      width: 848px;
      max-width: 848px;
      margin: 0 auto;
      padding-right: 45px;
      color: #4d4d4d;
    }

    .container {
      padding-top: 2em !important;
      padding-left: 45px !important;
      width: 100% !important;
      max-width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .header-persistent-content {
        flex-basis: content;
        font-size: 1.4rem !important;
      }
      button.need-help-sheet-btn {
        border-width: 1.5px;
        font-size: 1.4rem;
      }
      &:after {
        content: none !important;
      }
      .header-home-link {
        visibility: visible;
        height: initial;

        img{
          max-height: 80px;
          max-width: 200px;
        }
      }
    }

    #main-content-v2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      padding: 0;
      &:after {
        content: none !important;
      }

      #right, #left {
        width: 432px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 2em;
      }

      #right {
        align-content: start;
        .quickpay-actions {
          display: flex;
          flex-direction: column;
          button.base-btn {
            font-size: 2.4rem;
            font-weight: 400;
            padding: 3.2rem;
            margin-bottom: 16px;
            &.partial-payment {
              border-color: $global-color-secondary;
              color: $global-color-secondary;
            }
            .v-btn__content {
              margin-top: -1.6rem;
            }
          }
        }
        .view-bill-details {
          margin-top: 2.4rem;
          margin-bottom: 6rem;
        }
        .salutation {
          font-size: 3.2rem;
          font-weight: regular;
        }
        .explanation {
          font-size: 2.4rem;
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .provider-name {
          display: block;
        }
      }

      #left {
        .fancy-background {
          align-items: center;
          align-content: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-top: 20px;
          padding-bottom: 80px;
          width: 432px !important;
          height: 577px;
          background: center / contain no-repeat url("../../public/assets/images/corner-fold-page.png");

          .amount-due {
              font-size: 6rem;
              font-weight: 500;
              line-height: 8rem;
              padding-bottom: 14px;
          }

          .amount-due-label {
              font-size: 2.4rem;
          }
        }
        .vuetify.status-chip {
          .v-chip {
            font-size: 2rem !important;
            padding: 24px;
            font-weight: 500;
            letter-spacing: 0.15rem;
            text-transform: initial;
            .v-icon {
                margin-right: 0.4rem;
                height: 1.6rem;
                width: 1.6rem;
            }
          }
        }
      }
    }
  }
  @include desktop{
    #app-wrap {
      width: 1000px;
      max-width: 1000px;
    }
  }
}
</style>