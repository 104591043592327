import http from '../../utils/http';

const state = {
    currentPaymentData: {
        provider: null,
        account: null,
        estimate: null,
        subAccount: null
    },
    isGuestPay: false,
    guestPayFormData: {
        email: null,
        acceptanceOfTerms: null
    },
    isQuickPay: false,
    quickPayType: null,
    googlePayToken: null
};

const getters = {
    currentPaymentData(state) {
        return state.currentPaymentData;
    },
    isGuestPay(state) {
        return state.isGuestPay;
    },
    guestPayFormData(state) {
        return state.guestPayFormData;
    },
    isQuickPay(state) {
        return state.isQuickPay;
    },
    quickPayType(state) {
        return state.quickPayType;
    },
    googlePayClient(_, getters) {
        let environment = "TEST";
        if (getters.config.vueEnv === "production") {
            environment = "PRODUCTION";
        }
        return new google.payments.api.PaymentsClient({environment});
    },
    googlePayBaseRequest() {
        return {
            apiVersion: 2,
            apiVersionMinor: 0
        };
    },
    googlePayAllowedMethods() {
        return {
            type: 'CARD',
            parameters: {
                allowedAuthMethods: ["PAN_ONLY"],
                allowedCardNetworks: ["AMEX", "DISCOVER", "MASTERCARD", "VISA"]
            }
        }
    },
    googlePayDataRequest(_, getters) {
        let paymentDataRequest = Object.assign({}, getters.googlePayBaseRequest);
        paymentDataRequest.allowedPaymentMethods = [Object.assign(
            {
                tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                        'gateway': 'bluefin',
                        'gatewayMerchantId': '220614996321'
                    }
                }
            },
            getters.googlePayAllowedMethods
        )];
        if (getters.config.vueEnv === "production") {
            paymentDataRequest.allowedPaymentMethods[0].tokenizationSpecification.parameters.gatewayMerchantId = '120615647591';
        }

        paymentDataRequest.transactionInfo = {
            countryCode: 'US',
            currencyCode: 'USD',
            totalPriceStatus: 'FINAL',
            totalPrice: '1.00' // will be updated later
        };
        paymentDataRequest.merchantInfo = {
            merchantId: '12345678901234567890', // will be updated later
            merchantName: 'Waystar, Inc.' // will be updated later
        };
        return paymentDataRequest;
    },
    googlePayRequest(_, getters) {
        return Object.assign(
            {},
            getters.googlePayBaseRequest,
            {
                allowedPaymentMethods: [getters.googlePayAllowedMethods]
            }
        );
    },
    googlePayToken(state) {
        return state.googlePayToken;
    }
};

const mutations = {
    setCurrentPaymentData(state, val) {
        state.currentPaymentData = val;
    },
    setIsGuestPay(state, val) {
        state.isGuestPay = val;
    },
    setGuestPayFormData(state, val) {
        state.guestPayFormData = val;
    },
    setIsQuickPay(state, val) {
        state.isQuickPay = val;
    },
    setQuickPayType(state, val) {
        state.quickPayType = val;
    },
    setGooglePayToken(state, val) {
        state.googlePayToken = val;
    }
};

const actions = {
    postApplePayToken({dispatch}, { amount, date, applePayToken, secureCode, paymentType, estimateId, voucherNumber }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { amount, date, applePayToken, secureCode, paymentType, estimateId, voucherNumber },
            url: 'payments/applePayToken',
        }).then((r)=>{
            dispatch('syncProviders');
            return r;
        });
    },
    postGooglePayToken({dispatch}, { amount, date, googlePayToken, secureCode, paymentType, estimateId, voucherNumber, isGuestPay, email }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { amount, date, googlePayToken, secureCode, paymentType, estimateId, voucherNumber, isGuestPay, email },
            url: 'payments/googlePayToken',
        }).then((r)=>{
            if (!isGuestPay) {
                dispatch('syncProviders');
            }
            return r;
        });
    },
    fetchPaymentPlanDetails({}, planId) {
        return http.getDataOrThrow({
            method: 'GET',
            url: `paymentPlans/${planId}`,
        });
    },
    updatePaymentForm({dispatch}, { planId, paymentFormId }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { planId:parseInt(planId), paymentFormId },
            url: 'paymentPlans/updateMethod',
        }).then((r)=>{
            dispatch('syncProviders');
            return r;
        });
    },
    fetchPaymentDetails({}, paymentId) {
        return http.getDataOrThrow({
            method: 'GET',
            url: `payments/${paymentId}`,
        });
    },
    fetchPaymentAgreementDetails({}, paymentAgreementId) {
        return http.getDataOrThrow({
            method: 'GET',
            url: `payments/paymentAgreement/${paymentAgreementId}`,
        });
    },
    cancelPayment({dispatch}, paymentId) {
        return http.getDataOrThrow({
            method: 'DELETE',
            data: { paymentId },
            url: `payments/${paymentId}`,
        }).then((r)=>{
            dispatch('syncProviders');
            return r;
        });
    },
    logUsage({}, { type, event, secureCode }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { type, event, secureCode },
            url: 'usageData/log',
        });
    },
    recordUsage({}, { providerId, statType, statGroup, statValue }) {
        return http.getDataOrThrow({
            method: 'POST',
            data: { providerId, statType, statGroup, statValue },
            url: 'usageData/record',
        });
    },
};

export default {
    state, getters, mutations, actions,
};
