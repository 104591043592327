<template>
<modal :show="isVisible" @close="close()" class="vuetify composition-modal-container">
    <div>
        <div class="compose-content vuetify">
            <ComposeMessageNoProviders v-if="noProviders"></ComposeMessageNoProviders>
			<div v-else data-testid="compose-message-form">
				<h2 class="header-hidden">{{ $translate('hiddenHeaders.inputMessage') }}</h2>
				<base-form ref="form" name="forms.composeForm" @submit.prevent v-model="isFormValid">
                    <div class="form-title">{{ $translate('message.newMessage') }}</div>
                    <div data-testid="compose-message-provider-list">
                    <base-select
                        v-model="selectedProvider"
                        :items="providers"
                        item-title="name"
                        item-value="id"
                        required
                        :label="providerLabel"
                        class="provider-list"
                        ></base-select>
                    </div>
                    <div data-testid="compose-message-account-list">
                    <base-select
                        v-model="selectedAccount"
                        :items="accounts"
                        item-title="description"
                        item-value="accountId"
                        required
                        :label="accountLabel"
                        class="account-list"
                        ></base-select>
                    </div>
                    <div data-testid="compose-message-reasons-list">
                    <base-select
                        v-model="selectedReason"
                        :items="reasons"
                        item-title="messageName"
                        item-value="id"
                        :label="reasonLabel"
                        class="reasons-list"
                        required
                        ></base-select>
                    </div>
					<div>
                        <base-textarea v-model="messageDetails.message" name="message"
                            required :maxlength="maxMessageLength"
                            :placeholder="$translate('message.startMessagePlaceholder')"
                            :label="$translate('message.message')"
                            class="compose-message-textarea"
                            :rules="messageRules"
                            rows="5" row-height="25" auto-grow></base-textarea>
					</div>
					<div :class="{errored: hasAttachmentErrors()}">
                            <div class="vuetify keep-right attachment-button">
                                <base-btn data-testid="compose-message-attach-button" class="button-secondary" variant="text" :disabled="messageDetails.attachments.length >= 3" @click="focusAttachment">Attach a file <base-icon right icon="mdi-attachment"></base-icon></base-btn>
                            </div>
							<input ref="attachmentInput" class="fs-block" type="file" :focus="messageAttachmentFocus" v-show="false" accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.txt" @change="addAttachment" multiple>
							<ComposeMessageMessageAttachments :attachments="messageDetails.attachments"></ComposeMessageMessageAttachments>
						<div class="interaction-feedback vue-messages" v-if="hasAttachmentErrors()">
							<div v-if="attachmentErrors.tooManyFiles" class="attachment-error-message vue-message">
                                {{ $translate('errors.tooManyFiles') }}
							</div>
							<div v-if="attachmentErrors.genericFileError" class="attachment-error-message vue-message">
								{{ $translate('errors.genericFileError') }}
							</div>
							<div v-if="attachmentErrors.fileTooLarge" class="attachment-error-message vue-message">
								{{ $translate('errors.fileTooLarge') }}
							</div>
							<div v-if="attachmentErrors.invalidFileType" class="attachment-error-message vue-message">
								{{ $translate('errors.invalidFileType') }}
							</div>
							<div v-if="attachmentErrors.messageRequired" class="attachment-error-message vue-message">
								{{ $translate('errors.genericRequired') }}
							</div>
						</div>
					</div>
					<div class="vuetify keep-right form-buttons">
                        <base-btn class="button-bigger-text" variant="text" data-testid="compose-message-cancel-button" @click="close">{{ $translate('actions.cancel') }}</base-btn>
                        <base-btn class="button-bigger-text v-button-primary" :disabled="disableForm()" data-testid="compose-message-send-button" @click.prevent="startSendingMessage();">{{ sending ? $translate('actions.sending') : $translate('actions.send') }}</base-btn>
                    </div>
                </base-form>
			</div>

			<div v-show="providerMessagingDisabled">
				<div class="modal-subcontent">{{ $translate('message.argProviderCantMessage') }}</div>
			</div>
		</div>
    </div>
</modal>
</template>

<script>
    import BaseTextarea from './BaseTextarea.vue';
    import _ from 'lodash';
    import { translate } from './../utils/utils.js';
    import ComposeMessageNoProviders from './ComposeMessageNoProviders.vue';
    import ComposeMessageMessageAttachments from './ComposeMessageMessageAttachments.vue';
    import Modal from './Modal.vue';
    import BaseIcon from './BaseIcon.vue';
    import BaseBtn from './BaseBtn.vue';
    import BaseForm from './BaseForm.vue';
    import BaseSelect from './BaseSelect.vue';

    export default {
        name: 'ComposeMessageModal',
        components: {
            BaseTextarea,
            ComposeMessageNoProviders,
            ComposeMessageMessageAttachments,
            Modal,
            BaseIcon,
            BaseBtn,
            BaseForm,
            BaseSelect
        },
        computed: {
            currentBill() {
                return this.$store.getters.currentBill;
            },
            currentEstimate() {
                return this.$store.getters.currentEstimate;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
            hasBreakingAttachmentErrors() {
                return this.attachmentErrors.fileTooLarge || this.attachmentErrors.tooManyFiles || this.attachmentErrors.invalidFileType;
            },
            providers() {
                return this.$store.getters.providers.filter(p => p.canMessage);
            },
            reasonsObj() {
                return this.$store.getters.reasonsObj;
            },
            currentProvider() {
                if (this.$store.getters.currentProvider?.canMessage) {
                    return this.$store.getters.currentProvider;
                }
                return null;
            },
        },
        data: () => ({
            isVisible: false,
            messageDetails: {
                secureCode: null,
                accountId : null,
                providerId : null,
                message: '',
                attachments: [],
                reason: -1,
            },
            warningMessageLength: 4900,
            maxMessageLength: 5000,
            messageAttachmentFocus: 0,
            attachmentErrors: {},
            accounts: [],
            reasons: [],
            selectedProvider: null,
            selectedAccount: null,
            selectedReason: null,
            noProviders: false,
            providerMessagingDisabled: false,
            sending: false,
            forms: {
                composeForm: {
                    errors: {
                        messageRequired: false,
                        reasonRequired: false,
                    },
                    submitted: false,
                },
            },
            messageRules: [],
            isFormValid: false,
            accountLabel: translate('message.selectAccount'),
            providerLabel: translate('message.selectProvider'),
            reasonLabel: translate('message.reasons.default'),
        }),
        watch: {
            providers() {
                if (this.providers.length == 0) {
                    this.noProviders = true;
                    console.error('No providers to help build the view');
                    return;
                } else {
                    this.noProviders = false;
                    if (this.providers.length == 1) {
                        this.selectedProvider = this.providers[0];
                    }
                }
            },
            selectedProvider() {
                if (this.selectedProvider) {
                    if(!this.selectedProvider.canMessage){
                        this.providerMessagingDisabled = true;
                        return;
                    }

                    if (this.selectedProvider.requiresRedirectToBranded && this.isVisible) {
                        this.emitter.emit('redirect:tab', {
                            provider: this.selectedProvider,
                            context: {
                                stateGo: {
                                    to: this.$route.name,
                                    toParams: this.$route.params,
                                },
                                emit: ['message:compose']
                            }
                        });
                        this.isVisible = false;
                        return;
                    }

                    this.accounts = this.selectedProvider.accounts;
                    this.messageDetails.providerId = this.selectedProvider.id;
                    this.providerLabel = this.$translate('message.provider');
                    this.reasons = this.messageReasonsForProvider(this.selectedProvider);
                } else {
                    this.providerLabel = this.$translate('message.selectProvider');
                }
            },
            isVisible() {
                if (this.selectedProvider && this.selectedProvider.requiresRedirectToBranded && this.isVisible) {
                    this.emitter.emit('redirect:tab', {
                        provider: this.selectedProvider,
                        context: {
                            stateGo: {
                                to: this.$route.name,
                                toParams: this.$route.params,
                            },
                            emit: ['message:compose']
                        }
                    });
                    this.isVisible = false;
                    return;
                }
            },
            accounts() {
                if (this.accounts) {
                    this.accounts.forEach((a) => {
                        a.description = a.accountNumber + (a.amountDue > 0 ? ": " + this.$formatCurrency(a.amountDue) : '');
                    });

                    if (this.accounts.length == 1) {
                        this.selectedAccount = this.accounts[0];
                    }
                }
            },
            selectedAccount() {
                if (this.selectedAccount) {
                    if (!this.selectedAccount.description) {
                        this.selectedAccount.description = this.selectedAccount.accountNumber + ": " + this.$formatCurrency(this.selectedAccount.amountDue);
                    }
                    if (!this.selectedProvider) {
                        this.selectedProvider = _.find(this.providers, (provider) => {
                            return provider.id === this.selectedAccount.provider.id;
                        });
                        if (!this.selectedProvider) {
                            this.selectedAccount = null;
                        }
                    }
                    this.messageDetails.accountId = this.selectedAccount.accountId;
                    this.messageDetails.secureCode = this.selectedAccount.secureCode || null;
                    this.showMessageInputs = true;
                    if (!this.messageDetails.secureCode && this.selectedAccount.estimateId) {
                        this.messageDetails.reason = 11;
                        this.selectedReason = _.find(this.messageReasonsForProvider(this.selectedProvider), { id: this.messageDetails.reason });
                    }
                    this.accountLabel = this.$translate('message.account');
                } else {
                    this.accountLabel = this.$translate('message.selectAccount');
                }
            },
            selectedReason() {
                if (this.selectedReason) {
                    if (!this.messageDetails.reason || this.messageDetails.reason == -1) {
                        this.messageDetails.reason = this.selectedReason.id;
                    }
                    this.reasonLabel = this.$translate('message.reason');
                } else {
                    this.reasonLabel = this.$translate('message.reasons.default');
                }
            },
            "messageDetails.reason": {
                handler() {
                    if (this.messageDetails.reason && this.selectedProvider) {
                        this.selectedReason = _.find(this.messageReasonsForProvider(this.selectedProvider), { id: this.messageDetails.reason });
                    }
                }
            }
        },
        methods: {
            messageReasonsForProvider(targetProvider) {
                var features = (targetProvider && targetProvider.features) ? targetProvider.features : [];
                return _.map(
                    _.filter(this.reasonsObj, function(reason) {
                        return _.every(reason.requiredFeatures, function(requiredFeature) {
                            return _.includes(features, requiredFeature);
                        });
                    }),
                    function(reason) {
                        return _.pick(reason, 'id', 'messageName');
                    });
            },
            messageRequired(){
                if (!Boolean(this.messageDetails.message)) {
                    return this.$translate('errors.messageRequired');
                }
                return true;
            },
            messageCounter() {
                if (this.messageDetails.message.length >= this.warningMessageLength) {
                    return this.$translate('errors.runningOutOfCharacters', {current: this.messageDetails.message.length, allowed: this.maxMessageLength});
                }
                return true;
            },
            close() {
                this.isVisible = false;
            },
            hasAttachmentErrors() {
                for(let prop in this.attachmentErrors){
                    if(this.attachmentErrors[prop]){
                        return true;
                    }
                }
                return false;
            },
            disableForm() {
                if (this.selectedProvider && this.selectedAccount && (this.messageDetails.reason && this.messageDetails.reason !== -1) && this.messageDetails.message) {
                    return false;
                }
                return true;
            },
            startSendingMessage() {
                // we set the tooManyFiles error as a warning to the user. However, as long as the issue is resolved, we can ignore the error
                this.forms.composeForm.submitted = true;
                if (this.messageDetails.attachments && this.attachmentErrors) {
                    this.attachmentErrors.tooManyFiles &= this.messageDetails.attachments.length > 3;
                }
                if(this.forms.composeForm.errors.messageRequired || this.forms.composeForm.errors.reasonRequired || !this.messageDetails.message || this.sending || this.hasBreakingAttachmentErrors) {
                    return;
                }

                this.sending = true;
                this.isFormValid = false;

                //threadId set to null because it is a new message
                this.$store.dispatch('sendMessage', {messageDetails: this.messageDetails, threadId: null})
                    .then((messageId) => {

                        this.isVisible = false;

                        this.emitter.emit('simpleModal:showPrompt', {
                            header: this.$translate('message.messageSent'),
                            subcontent: this.$translate('dialogs.copySentTo', {email: this.currentUser.email}),
                            intent: 'success',
                            actions: [{
                                label: this.$translate('actions.viewMessage'),
                                clickHandler: () => {
                                    this.$store.dispatch('setThreadId', messageId);
                                    this.$router.push({
                                        name: 'MessageDetails',
                                        params: {
                                            threadId: messageId
                                        },
                                    });
                                }
                            }]
                        });

                        // some pages need to refresh their content because of
                        // this event. So let them know it happened
                        this.emitter.emit('message:newThreadCreated', {
                            providerId: this.messageDetails.providerId,
                            accountId: this.messageDetails.accountId
                        });
                    })
                    .catch((resp) => {
                        if ('TOO_MANY_FILES' === resp.data) {
                            this.attachmentErrors.tooManyFiles = true;
                        } else if ('GENERIC_FILE_ERROR' === resp.data) {
                            this.attachmentErrors.genericFileError = true;
                        } else if ('FILE_TOO_LARGE' === resp.data) {
                            this.attachmentErrors.fileTooLarge = true;
                        }else if ('INVALID_FILE_TYPE' === resp.data) {
                            this.attachmentErrors.invalidFileType = true;
                        } else {
                            this.attachmentErrors.genericFileError = true;
                        }
                    })
                    .finally(() => {
                        this.sending = false;
                    });
            },
            focusAttachment() {
                this.$refs.attachmentInput.click();
            },
            removeAttachment(index) {
                this.messageDetails.attachments.splice(index, 1);
                this.attachmentErrors = {}; // clear errors
            },
            addAttachment: function(e) {
                const files = e.target.files;
                this.attachmentErrors.tooManyFiles = files.length + this.messageDetails.attachments.length > 3;
                for (let i = 0; i < files.length; i++) {
                    if (this.messageDetails.attachments.length < 3) {
                        this.messageDetails.attachments.splice(this.messageDetails.attachments.length, 1, files[i]);
                        this.messageAttachmentFocus = this.messageDetails.attachments.length;
                    }
                }
                this.$forceUpdate();
            },
            setup(gatherByData) {
                // show popup
                // if we get account details, prepopulate our modal
                // with those details
                if (this.currentUser && this.currentUser.patientUserId) {
                    this.$store.dispatch('getProviders');
                }

                gatherByData = gatherByData ||= {};
                this.messageDetails.reason = gatherByData.reason || -1;
                this.messageDetails.message = gatherByData.message || '';

                if ('useProviderAccount' in gatherByData) {
                    this.selectedProvider = this.currentProvider;
                } else if('secureCode' in gatherByData) {
                    _.forEach(this.providers, (provider) => {
                        let account = _.find(provider.accounts, { secureCode: gatherByData.secureCode });
                        if (account) {
                            this.selectedAccount = account;
                            return;
                        }
                    });
                } else if('useCurrentBill' in gatherByData) {
                    _.forEach(this.providers, (provider) => {
                        let account = _.find(provider.accounts, { secureCode: this.currentBill.secureCode });
                        if (account) {
                            this.selectedAccount = account;
                            return;
                        }
                    });
                } else if('estimateId' in gatherByData) {
                    _.forEach(this.providers, (provider) => {
                        // If there is a single estimate
                        var account = _.find(provider.accounts, { estimateId: gatherByData.estimateId });
                        // If there are multiple estimates
                        if (!account) {
                            var current_account;
                            provider.accounts.forEach(function (a) {
                                current_account = a;
                                a.estimates.forEach(function (e) {
                                    if (e.id == gatherByData.estimateId) {
                                        account = current_account;
                                    }
                                });
                            });
                        }
                        this.selectedAccount = account;
                    });
                } else if('useCurrentEstimate' in gatherByData) {
                    var estimateId = this.currentEstimate.id;
                    _.forEach(this.providers, (provider) => {
                        // If there is a single estimate
                        var account = _.find(provider.accounts, { estimateId: estimateId });
                        // If there are multiple estimates
                        if (!account) {
                            var current_account;
                            provider.accounts.forEach(function (a) {
                                current_account = a;
                                a.estimates.forEach(function (e) {
                                    if (e.id == estimateId) {
                                        account = current_account;
                                    }
                                });
                            });
                        }
                        this.selectedAccount = account;
                    });
                } else if('account' in gatherByData && gatherByData.account) {
                    this.selectedAccount = gatherByData.account;
                } else if ('providerId' in gatherByData && 'accountId' in gatherByData) {
                    if (!this.providers || !Object.keys(this.providers).length) {
                        this.$store.dispatch('getProviderById', gatherByData.providerId).then((provider) => {
                            this.selectedProvider = provider;
                            this.selectedAccount = _.find(this.selectedProvider.accounts, (account) => {
                                return account.accountId.toString() === gatherByData.accountId.toString();
                            });
                        }).catch(err => {
                            console.error('Error trying to fetch the specified provider: ', err);
                            this.selectedProvider = null;
                            this.selectedAccount = null;
                        });
                    } else {
                        this.selectedProvider = _.find(this.providers, (provider) => {
                            return provider.id === gatherByData.providerId;
                        });
                        this.selectedAccount = _.find(this.selectedProvider.accounts, (account) => {
                            return account.accountId.toString() === gatherByData.accountId.toString();
                        });
                    }
                } else {
                    this.noProviders = false;
                    if (this.providers.length == 0) {
                        this.noProviders = true;
                        console.error('No providers to help build the view');
                        return;
                    } else if (this.providers.length == 1) {
                        this.selectedProvider = this.providers[0];
                    }
                }

                if (this.currentUser && this.currentUser.patientUserId) {
                    this.isVisible = true;
                }
            },
        },
        created() {
            this.messageRules.push(this.messageRequired);
            this.messageRules.push(this.messageCounter);
            // $emit this event to trigger the composition modal into view.
            // The gatherByData can come in 3 ways
            //      String :: we assume that if gatherByData is a string it is an sCode
            //      Boolean :: we assume that if you pass true boolean primitive that
            //              you want us to build the info off of the current bill
            //      Anything else, we simply ignore but by triggering this event
            //          we load the ui to be built off the user'r providers and accounts
            //
            // Passing the method this way allows us to be exact with our expectations
            //  on what's available as well as reduces performance load by building the
            //  ui on data we have available to us currently instead of an ajax call.
            this.emitter.on('message:compose', (gatherByData) => {
                this.setup(gatherByData);
            });
            this.emitter.on('attachment:remove', (index) => {
                this.removeAttachment(index);
            });
        },
        destroyed() {
            this.emitter.off('message:newThreadCreated');
        },
    };
</script>
<style lang="scss">
@import '../styles/variables.scss';
.composition-modal-container {
    .form-title {
        margin-bottom: 25px;
    }

    .form-buttons {
        margin-top: 40px;
    }

    .attachment-button {
        margin-top: 0.5rem;

        button {
            font-size: 1.2rem;
            text-transform: none;
        }

        i {
            margin-left: 0.8rem;
        }
    }

    fieldset {
        padding: 0;
        margin-bottom: 1.5rem !important;
    }

    textarea {
        margin-bottom: 1.5rem !important;
    }
}
</style>