<template>
<div class="payment-flow-amount-step vuetify">
    <div class="payment-flow-step-title">{{ $translate('paymentFlow.steps.amount') }}</div>
    <div v-if="$vuetify.display.lgAndUp" class="payment-flow-step-prompt">{{ $translate('paymentFlow.amount.desktopPrompt') }}</div>
    <div v-else class="payment-flow-step-prompt">{{ $translate('paymentFlow.amount.mobilePrompt') }}</div>
    <base-form v-model="isValid" @submit.prevent>
        <base-expansion-panels v-model="amountOptionTypeIndex" mandatory>
            <base-expansion-panel v-if="validPaymentOptions.accountBalanceDiscount" :selected="validPaymentOptions.accountBalanceDiscount.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    <div class="account-discount">
                        <span class="discount-prompt">{{  $translate('paymentFlow.amount.accountBalanceDiscountTitle', { percent: validPaymentOptions.accountBalanceDiscount.discountPolicy } )}}</span>
                        <span class="discount-old-amount-due">{{ $formatCurrency(totalAmount) }}</span>
                        <span class="discount-new-amount-due">{{ $formatCurrency(validPaymentOptions.accountBalanceDiscount.amount) }}</span>
                    </div>
                </template>
                <template #subtitle>
                    {{ $translate('paymentFlow.amount.oneTimePayment') }}
                </template>
            </base-expansion-panel>
            <base-expansion-panel  v-if="validPaymentOptions.accountBalance" :selected="validPaymentOptions.accountBalance.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    {{ $translate('paymentFlow.amount.accountBalanceTitle', { amount: $formatCurrency(validPaymentOptions.accountBalance.amount) }) }}
                </template>
                <template #subtitle>
                    {{ $translate('paymentFlow.amount.oneTimePayment') }}
                </template>
            </base-expansion-panel>
            <base-expansion-panel v-if="validPaymentOptions.statementBalanceDiscount" :selected="validPaymentOptions.statementBalanceDiscount.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    <div class="statement-discount">
                        <span class="discount-prompt">{{  $translate('paymentFlow.amount.statementBalanceDiscountTitle', { percent: validPaymentOptions.statementBalanceDiscount.discountPolicy } )}}</span>
                        <span class="discount-old-amount-due">{{ $formatCurrency(statementAmount) }}</span>
                        <span class="discount-new-amount-due">{{ $formatCurrency(validPaymentOptions.statementBalanceDiscount.amount) }}</span>
                    </div>
                </template>
                <template #subtitle>
                    {{ $translate('paymentFlow.amount.oneTimePayment') }}
                </template>
            </base-expansion-panel>
            <base-expansion-panel v-if="validPaymentOptions.statementBalance" :selected="validPaymentOptions.statementBalance.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    {{ $translate('paymentFlow.amount.statementBalanceTitle', { amount: $formatCurrency(validPaymentOptions.statementBalance.amount) }) }}
                </template>
                <template #subtitle>
                    {{ $translate('paymentFlow.amount.oneTimePayment') }}
                </template>
            </base-expansion-panel>
            <base-expansion-panel v-if="validPaymentOptions.estimateTotalAmount" :selected="validPaymentOptions.estimateTotalAmount.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    {{ estimateTotalTitle }}
                </template>
                <template #subtitle>
                    {{ $translate('paymentFlow.amount.oneTimePayment') }}
                </template>
            </base-expansion-panel>
            <base-expansion-panel v-if="validPaymentOptions.estimateRequestedAmount" :selected="validPaymentOptions.estimateRequestedAmount.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    {{  estimateRequestedTitle }}
                </template>
                <template #subtitle>
                    {{ $translate('paymentFlow.amount.estimateRequestedAmountSubtitle') }}
                </template>
            </base-expansion-panel>
            <base-expansion-panel v-if="validPaymentOptions.subAccontTotalAmount" :selected="validPaymentOptions.subAccontTotalAmount.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    {{ $translate('paymentFlow.amount.subAccountBalanceTitle', { amount: $formatCurrency(totalAmount) }) }}
                </template>
                <template #subtitle>
                    {{ $translate('paymentFlow.amount.oneTimePayment') }}
                </template>
            </base-expansion-panel>
            <payment-flow-amount-step-payment-plan
                :selected="validPaymentOptions.paymentPlan.optionTypeIndex === amountOptionTypeIndex"
                :options="paymentPlanRadioGroupOptions"
                v-model="paymentPlanInstallmentCount"
                v-if="validPaymentOptions.paymentPlan"
            >
            </payment-flow-amount-step-payment-plan>
            <payment-flow-amount-step-payment-plan
                :selected="validPaymentOptions.updatePlan.optionTypeIndex === amountOptionTypeIndex"
                :options="paymentPlanRadioGroupOptions"
                :current-plan="currentPlanData"
                v-model="paymentPlanInstallmentCount"
                v-if="validPaymentOptions.updatePlan"
            >
            </payment-flow-amount-step-payment-plan>
            <base-expansion-panel class="financing-option" v-if="validPaymentOptions.accessOne" :selected="validPaymentOptions.accessOne.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    {{ $translate('payment.amount.lowMonthlyPaymentOptions') }}<span v-if="financingDisclaimers && financingDisclaimers.generalFinancing">*</span>
                </template>
            </base-expansion-panel>
            <base-expansion-panel class="financing-option" v-if="validPaymentOptions.clearBalance" :selected="validPaymentOptions.clearBalance.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    {{ $translate('payment.amount.lowMonthlyPaymentOptions') }}<span v-if="financingDisclaimers && financingDisclaimers.generalFinancing">*</span>
                </template>
            </base-expansion-panel>
            <base-expansion-panel class="financing-option" v-if="validPaymentOptions.curae" :selected="validPaymentOptions.curae.optionTypeIndex === amountOptionTypeIndex">
                <template #title>
                    {{ $translate('payment.amount.lowMonthlyPaymentOptions') }}<span v-if="financingDisclaimers && financingDisclaimers.generalFinancing">*</span>
                </template>
            </base-expansion-panel>
            <payment-flow-amount-step-amount-card
                :max-amount="totalAmount"
                :min-amount="minPartialAmount"
                :selected="validPaymentOptions.partialAmount.optionTypeIndex === amountOptionTypeIndex"
                v-model="validPaymentOptions.partialAmount.amount"
                v-if="validPaymentOptions.partialAmount"
                class="partial-payment-card"
            >
            </payment-flow-amount-step-amount-card>
        </base-expansion-panels>
    </base-form>
    <div v-if="financingDisclaimers && financingDisclaimers.generalFinancing && (validPaymentOptions.accessOne || validPaymentOptions.clearBalance || validPaymentOptions.curae)" class="financing-disclaimer">
        * {{ financingDisclaimers.generalFinancing }}
    </div>
    <div class="navigation-buttons">
        <base-btn @click="back()" class="back-btn" variant="outlined">{{ $translate('paymentFlow.actions.back') }}</base-btn>
        <base-spacer></base-spacer>
        <base-btn @click="cancel()" class="cancel-btn" variant="text">{{ $translate('paymentFlow.actions.cancel') }}</base-btn>
        <base-btn @click="updateAmount()" class="continue-btn v-button-primary" :disabled="contButtonDisabled">{{ $translate('paymentFlow.actions.continue') }}</base-btn>
    </div>
</div>
</template>
<script>
import PaymentFlowAmountStepAmountCard from './PaymentFlowAmountStepAmountCard.vue';
import PaymentFlowAmountStepPaymentPlan from './PaymentFlowAmountStepPaymentPlan.vue';
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import BaseBtn from './BaseBtn.vue';
import BaseExpansionPanels from './BaseExpansionPanels.vue';
import BaseForm from './BaseForm.vue';
import BaseSpacer from './BaseSpacer.vue';
import BaseRadioGroup from './BaseRadioGroup.vue';
import { parseAmount } from './../utils/utils.js';

export default {
    name: 'PaymentFlowAmountStep',
    components: {
        PaymentFlowAmountStepAmountCard,
        PaymentFlowAmountStepPaymentPlan,
        BaseExpansionPanel,
        BaseBtn,
        BaseExpansionPanels,
        BaseForm,
        BaseSpacer,
        BaseRadioGroup
    },
    props: {
        allowEstimatePartialPayments: {
            type: Boolean,
            default: false,
        },
        allowPlanUpdate: Boolean,
        alreadyHasFinancing: {
            type: Boolean,
            default: false,
        },
        amount: String,
        amountOptionType: String,
        billPaymentOptions: Array,
        currentPlanData: Object,
        estimateRequestedAmount: Number,
        estimateRequestedAmountPercent: Number,
        estimateRequestedTotal: Number,
        financingDisclaimers: Object,
        financingVendorsAvailable: Array,
        hasFinancingOption: Boolean,
        installmentCount: Number,
        isEstimate: {
            type: Boolean,
            default: false,
        },
        isGettingFinancingOptions: Boolean,
        isSubAccount: {
            type: Boolean,
            default: false,
        },
        minPartialAmount: Number,
        noOffersFound: Boolean,
        paymentPlanInstallmentOptions: {
            type: Object,
            default: null,
        },
        shouldPreventPaymentPlanCreation: Boolean,
        statementAmount: Number,
        totalAmount: Number,
        hasBadDebt: Boolean,
        hasFinancingDefault: Boolean,
    },
    data: () => ({
        // Sub-account payments always have an O option type. Once we have more than sub-account payments, we need to expand this logic
        amountOptionTypeIndex: 0,
        dialog: false,
        isValid: false,
        paymentOption: null,
        validPaymentOptions: {
            accessOne: null,
            accountBalance: null,
            accountBalanceDiscount: null,
            clearBalance: null,
            curae: null,
            estimateTotalAmount: null,
            estimateRequestedAmount: null,
            partialAmount: null,
            paymentPlan: null,
            statementBalance: null,
            statementBalanceDiscount: null,
            subAccontTotalAmount: null,
            updatePlan: null,
        },
        paymentPlanInstallmentCount: null,
    }),
    watch: {
        alreadyHasFinancing(value) {
            if (value) {
                this.validPaymentOptions.curae = null;
            }
        }
    },
    computed: {
        availableInstallmentOptions() {
            if (this.totalAmount && this.paymentPlanInstallmentOptions) {
                let installmentOptionsForAmt = null;
                const keys = Object.keys(this.paymentPlanInstallmentOptions).filter((o) => !(parseAmount(o) === null) || 'inf' === o);
                var bucketLimits = keys.sort(function(a, b) {
                    if ('inf' === a) {
                        return 1;
                    }
                    if ('inf' === b) {
                        return -1;
                    }
                    const pa = parseAmount(a);
                    const pb = parseAmount(b);
                    return pa - pb;
                });
                for (var i in bucketLimits) {
                    var bucketLimit = bucketLimits[i];
                    if (bucketLimit !== 'inf' && parseAmount(bucketLimit) >= this.totalAmount) {
                        installmentOptionsForAmt = this.paymentPlanInstallmentOptions[bucketLimit];
                        break;
                    }
                }

                if (installmentOptionsForAmt == null) {
                    installmentOptionsForAmt = this.paymentPlanInstallmentOptions['inf'];  //nothing found.  use the last one.
                }
                return installmentOptionsForAmt;
            }
            return null;
        },
        paymentPlanRadioGroupOptions() {
            let options = [];
            if (this.availableInstallmentOptions.length) {
                this.availableInstallmentOptions.forEach((option) => {
                    options.push({
                        key: option,
                        title: this.getHeaderForInstallmentOption(option),
                        subtitle: this.$translate('paymentFlow.amount.paymentPlan.installmentOptionSubheader', { numberOfMonths: option })
                    });
                });
            }
            return options;
        },
        estimateAmountRemaining() {
            let remaining = this.estimateRequestedAmount - (this.estimateRequestedTotal - this.totalAmount);
            if (remaining >= 0) {
                return remaining;
            }
            return null;
        },
        estimateTotalTitle() {
            if (this.hasPartialEstimatePayment) {
                return this.$translate('paymentFlow.amount.estimateTotalAmountRemainingTitle', {
                    amount: this.$formatCurrency(this.totalAmount),
                });
            } else {
                return this.$translate('paymentFlow.amount.estimateTotalAmountTitle', {
                    amount: this.$formatCurrency(this.totalAmount),
                });
            }
        },
        estimateRequestedTitle() {
            if (this.hasPartialEstimatePayment) {
                return this.$translate('paymentFlow.amount.estimateRequestedRemainingAmountTitle', {
                    amount: this.$formatCurrency(this.estimateAmountRemaining),
                });
            } else {
                return this.$translate('paymentFlow.amount.estimateRequestedAmountTitle', {
                    amount: this.$formatCurrency(this.estimateRequestedAmount),
                    percent: this.estimateRequestedAmountPercent,
                });
            }
        },
        hasPartialEstimatePayment() {
            if (this.estimateRequestedAmount && this.estimateRequestedTotal && this.totalAmount) {
                return (this.estimateRequestedTotal - this.totalAmount) !== 0;
            } else if (this.amount) {
                return (this.amount - this.totalAmount) !== 0;
            }
            return false;
        },
        paymentPlanOption() {
            return this.allowPlanUpdate ? this.validPaymentOptions.updatePlan : this.validPaymentOptions.paymentPlan;
        },
        shouldAllowPaymentPlan() {
            return !this.shouldPreventPaymentPlanCreation && !this.isSubAccount && Boolean(this.availableInstallmentOptions && this.availableInstallmentOptions.length);
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        },
        contButtonDisabled() {
            if (this.validPaymentOptions?.partialAmount?.optionTypeIndex === this.amountOptionTypeIndex) {
                if (!this.validPaymentOptions?.partialAmount?.amount) {
                    return true;
                }
            }
            return !this.isValid || this.isGettingFinancingOptions;
        },
    },
    methods: {
        getHeaderForInstallmentOption(option) {
            const amount = this.totalAmount / option;
            return this.$translate('paymentFlow.amount.paymentPlan.installmentOptionHeader', { 
                formattedAmount: this.$formatCurrency(amount) 
            });
        },
        updateAmount() {
            const currentAmountOption = Object.values(this.validPaymentOptions).filter(o => o && this.amountOptionTypeIndex === o.optionTypeIndex)[0];
            this.$emit('update-amount', {
                amount: currentAmountOption.amount.toString(),
                amountOptionType: currentAmountOption.type,
                financingVendor: currentAmountOption.financingVendor,
                installmentCount: (this.paymentPlanOption && this.paymentPlanOption.optionTypeIndex === this.amountOptionTypeIndex) ? this.paymentPlanInstallmentCount : null,
                maxDateForDiscountPolicy: currentAmountOption.maxDate,
            });
        },
        cancel() {
            this.$emit('cancel');
        },
        back() {
            this.$emit('back');
        },
    },
    created() {
        this.$emit('scroll-to-top');
    },
    mounted() {
        this.$emit('loaded');

        // Figure out which payment types we should allow and set them up in the right order
        let optionTypeIndex = 0;
        let hasPaymentPlan = this.currentPlanData ? Boolean(Object.keys(this.currentPlanData).length) : false;

        if (this.billPaymentOptions && this.billPaymentOptions.length) {
            // account level payment, so let's respect the backend's decision and then separately make a decision on payment plans and partial payments
            this.billPaymentOptions.forEach((option) => {
                if ('CB' === option.type) {
                    this.validPaymentOptions.accountBalance = { ...option, optionTypeIndex: optionTypeIndex++ };
                } else if ('CBD' === option.type && !this.isGuestPay) {
                    this.validPaymentOptions.accountBalanceDiscount = { ...option, optionTypeIndex: optionTypeIndex++ };
                } else if ('SB' === option.type && !this.isGuestPay) {
                    this.validPaymentOptions.statementBalance = { ...option, optionTypeIndex: optionTypeIndex++ };
                } else if ('SBD' === option.type && !this.isGuestPay) {
                    this.validPaymentOptions.statementBalanceDiscount = { ...option, optionTypeIndex: optionTypeIndex++ };
                }
            });
            if (this.allowPlanUpdate && hasPaymentPlan && Boolean(this.availableInstallmentOptions && this.availableInstallmentOptions.length) && !this.isGuestPay) {
                this.validPaymentOptions.updatePlan = { type: this.billPaymentOptions ? 'CB' : 'O', amount: this.totalAmount, optionTypeIndex: optionTypeIndex++ };
            }
        } else if (this.isEstimate) {
            this.validPaymentOptions.estimateTotalAmount = { type: 'O', amount: this.totalAmount, optionTypeIndex: optionTypeIndex++ };
            if (this.estimateRequestedAmount && this.estimateAmountRemaining && this.estimateAmountRemaining !== this.totalAmount) {
                this.validPaymentOptions.estimateRequestedAmount = { type: 'O', amount: this.estimateAmountRemaining, optionTypeIndex: optionTypeIndex++ };
            }
        } else if (this.isSubAccount) {
            this.validPaymentOptions.subAccontTotalAmount = { type: 'O', amount: this.totalAmount, optionTypeIndex: optionTypeIndex++ };
        }
        if (!this.allowPlanUpdate && this.shouldAllowPaymentPlan && !this.isGuestPay) {
            this.validPaymentOptions.paymentPlan = { type: this.billPaymentOptions ? 'CB' : 'O', amount: this.totalAmount, optionTypeIndex: optionTypeIndex++ };
        }
        if (this.financingVendorsAvailable && !this.isGuestPay) {
            if (this.financingVendorsAvailable.includes('AccessOne') && this.hasFinancingOption && !this.alreadyHasFinancing && !hasPaymentPlan && !this.hasBadDebt && !this.hasFinancingDefault) {
                this.validPaymentOptions.accessOne = { type: 'CB', amount: this.totalAmount, optionTypeIndex: optionTypeIndex++, financingVendor: 'AccessOne' };
            }
            if (this.financingVendorsAvailable.includes('ClearBalance') && this.hasFinancingOption && !this.alreadyHasFinancing && !hasPaymentPlan) {
                this.validPaymentOptions.clearBalance = { type: 'CB', amount: this.totalAmount, optionTypeIndex: optionTypeIndex++, financingVendor: 'ClearBalance' };
            }
            if (this.financingVendorsAvailable.includes('Curae') && this.hasFinancingOption && !this.noOffersFound && !this.alreadyHasFinancing  && !hasPaymentPlan) {
                this.validPaymentOptions.curae = { type: 'CB', amount: this.totalAmount, optionTypeIndex: optionTypeIndex++, financingVendor: 'Curae' };
            }
        }
        if (this.isEstimate) {
            if (this.allowEstimatePartialPayments) {
                this.validPaymentOptions.partialAmount = { type: 'O', optionTypeIndex: optionTypeIndex++ };
            } else {
                this.validPaymentOptions.partialAmount = null;
            }
        } else {
            this.validPaymentOptions.partialAmount = { type: 'O', optionTypeIndex: optionTypeIndex++ };
        }

        // Initialize nested props and initial state based on current state being passed down to us
        if (this.shouldAllowPaymentPlan || this.allowPlanUpdate && !this.isGuestPay) {
            this.paymentPlanInstallmentCount = this.availableInstallmentOptions[0];
        }
        const numericAmount = parseFloat(this.amount);
        if ('CBD' === this.amountOptionType) {
            this.amountOptionTypeIndex = this.validPaymentOptions.accountBalanceDiscount.optionTypeIndex;
        } else if ('SBD' === this.amountOptionType) {
            this.amountOptionTypeIndex = this.validPaymentOptions.statementBalanceDiscount.optionTypeIndex;
        } else if ('SB' === this.amountOptionType) {
            this.amountOptionTypeIndex = this.validPaymentOptions.statementBalance.optionTypeIndex;
        } else if ('CB' === this.amountOptionType && !(this.installmentCount && this.installmentCount > 1)) {
            this.amountOptionTypeIndex = this.validPaymentOptions.accountBalance.optionTypeIndex;
        } else if ('O' == this.amountOptionType && !this.amount) {
            this.amountOptionTypeIndex = this.validPaymentOptions.partialAmount.optionTypeIndex;
        } else if (this.installmentCount && this.installmentCount > 1) {
            this.amountOptionTypeIndex = this.paymentPlanOption.optionTypeIndex;
            this.paymentPlanInstallmentCount  = this.installmentCount;
        } else if (this.amount !== null && this.amount !== undefined && numericAmount !== this.totalAmount) {
            if (this.isEstimate && numericAmount === this.estimateAmountRemaining) {
                this.amountOptionTypeIndex = this.validPaymentOptions.estimateRequestedAmount.optionTypeIndex;
            } else {
                this.amountOptionTypeIndex = this.validPaymentOptions.partialAmount.optionTypeIndex;
                this.validPaymentOptions.partialAmount.amount = this.amount;
            }
        }
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.payment-flow-amount-step {
    .v-expansion-panel-content__wrap {
        padding-bottom: 0;
    }
    .v-messages {
        display: none;
    }
    .financing-disclaimer {
        font-size: 1.2rem;
        letter-spacing: 0.025rem;
        line-height: 1.6rem;
        @include wider-than-tablet {
            font-size: 1.4rem;
            line-height: 2rem;
            padding: 0 1.5rem;
        }
    }
    .discount-prompt {
        padding-right: 0.6rem;
    }
    .discount-old-amount-due {
        color: $global-color-main-grey;
        padding-right: 0.6rem;
        text-decoration: line-through;
    }
    .discount-new-amount-due {
        color: $global-color-success;
    }
    .update-plan-card-header {
        color: $global-color-font-new;
        padding: 0 1.2rem;
        .update-plan-block {
            background: #f5f5f5;
        }
        .update-plan-card-header-prompt {
            font-size: 1.2rem;
            margin-bottom: 1.5rem;
            @include wider-than-tablet {
                font-size: 1.4rem;
            }
        }
    }
    .payment-flow-amount-step-installment-options {
        margin-left: 4rem;
        .v-icon {
            height: 2.2rem;
            width: 2.2rem;
        }
        @include wider-than-tablet {
            .v-icon {
                height: 2.6rem;
                width: 2.6rem;
            }
        }
        .v-input--radio-group {
            margin-top: 0;
            .v-label {
                margin-bottom: 0;
            }
        }

        .payment-flow-amount-step-installment-radio {
            padding-bottom: 1.6rem;
            &:last-of-type {
                padding-bottom: 0;
            }
            .payment-flow-amount-step-installment-option-label {
                margin-left: 1.6rem;
                .payment-flow-amount-step-installment-option-header {
                    color: $global-color-font-new;
                    padding-left: 0;
                    font-size: 1.4rem;
                    font-weight: 500;
                    letter-spacing: 0.046rem;
                    line-height: 1.6rem;
                    padding-right: 0;
                    @include wider-than-tablet {
                        font-size: 1.6rem;
                        letter-spacing: 0.014rem;
                        line-height: 2.4rem;
                    }
                }
                .payment-flow-amount-step-installment-option-subheader {
                    color: $global-color-font-new;
                    padding-left: 0;
                    font-size: 1.2rem;
                    letter-spacing: 0.011rem;
                    line-height: 1.6rem;
                    @include wider-than-tablet {
                        font-size: 1.4rem;
                        letter-spacing: 0.01rem;
                        line-height: 2.4rem;
                    }
                }
            }
        }
    }
}
</style>